import {Injectable} from '@angular/core';
import {map} from 'rxjs';
import {ManagerFacade} from '../store/manager/+state/manager.facade';
import {NavigationService} from '../services/navigation.service';

@Injectable({
	providedIn: 'root'
})
export class ManagerOpenGuard {
	constructor(
		private navService: NavigationService,
		private managerFacade: ManagerFacade
	) {}
	// navigate to login page in case user token is not exist
	canActivate() {
		return this.managerFacade.venue$.pipe(
			map(venue => {
				if (!venue) {
					this.navService.managerPage();
				}
				return venue;
			})
		);
	}
}
