import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from 'src/app/material-module/material-module';
import {TranslateModule} from '@ngx-translate/core';
import {NgSelectModule} from '@ng-select/ng-select';
import {StockManagerHeaderFiltersComponent} from './stock-manager-header-filters/stock-manager-header-filters.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {StockManagerDrawArticlesComponent} from './stock-manager-draw-articles/stock-manager-draw-articles.component';
import {MinPricePipe} from 'src/app/pipes/minPrice.pipe';
import {CheckIfArticleListVisibleEnumPipe} from 'src/app/pipes/checkIfArticleListVisible.pipe';
import {StockManagerDrawArticlesNewComponent} from './stock-manager-draw-articles-new/stock-manager-draw-articles-new.component';

@NgModule({
	imports: [
		CommonModule,
		MaterialModule,
		TranslateModule,
		NgSelectModule,
		FormsModule,
		ReactiveFormsModule
	],
	declarations: [
		StockManagerHeaderFiltersComponent,
		StockManagerDrawArticlesComponent,
		MinPricePipe,
		CheckIfArticleListVisibleEnumPipe,
		StockManagerDrawArticlesNewComponent
	],
	exports: [
		StockManagerHeaderFiltersComponent,
		StockManagerDrawArticlesComponent,
		MinPricePipe,
		CheckIfArticleListVisibleEnumPipe,
		StockManagerDrawArticlesNewComponent
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class StockManagerComponentsModule {}
