import {Injectable} from '@angular/core';
import {Socket} from 'ngx-socket-io';
@Injectable()
export class SocketService {
	constructor(private socket: Socket) {}
	initial() {}
	getData(venueId: string, tableNumber: string) {
		this.socket.on(
			`terminalStatus_${venueId}_${tableNumber.replace(' ', '_')}`,
			(data: any) => {
				return {data};
			}
		);
	}
}
