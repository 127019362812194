import {createFeatureSelector, createSelector} from '@ngrx/store';
import {MANAGER_FEATURE_KEY, ManagerState} from './manager.reducer';

export const authState =
	createFeatureSelector<ManagerState>(MANAGER_FEATURE_KEY);

export const loaded = createSelector(
	authState,
	(state: ManagerState) => state.loaded
);
export const error = createSelector(
	authState,
	(state: ManagerState) => state.error
);

export const screensavers = createSelector(
	authState,
	(state: ManagerState) => state.screensavers
);

export const optionGroups = createSelector(
	authState,
	(state: ManagerState) => state.optionGroups
);

export const tables = createSelector(
	authState,
	(state: ManagerState) => state.tables
);

export const venue = createSelector(
	authState,
	(state: ManagerState) => state.venue
);

export const categories = createSelector(
	authState,
	(state: ManagerState) => state.categories
);

//listen orders

export const orders = createSelector(
	authState,
	(state: ManagerState) => state.orders
);

export const orderStatusFiler = createSelector(
	authState,
	(state: ManagerState) => state.orderStatusFiler
);
export const filtersStockManager = createSelector(
	authState,
	(state: ManagerState) => state.filters
);
export const messageUpdate = createSelector(
	authState,
	(state: ManagerState) => state.messageUpdate
);

export const errorSyncUber = createSelector(
	authState,
	(state: ManagerState) => state.errorSyncUber
);
export const errorSyncJet = createSelector(
	authState,
	(state: ManagerState) => state.errorSyncJet
);
export const errorSyncWolt = createSelector(
	authState,
	(state: ManagerState) => state.errorSyncWolt
);
export const successSync = createSelector(
	authState,
	(state: ManagerState) => state.successSync
);

export const ordersPool = createSelector(
	authState,
	(state: ManagerState) => state.ordersPool
);
export const updateStatusLoading = createSelector(
	authState,
	(state: ManagerState) => state.updateStatusLoading
);
