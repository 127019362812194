import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import * as fromManager from './manager.reducer';
import {ManagerEffects} from './manager.effects';
import {ManagerFacade} from './manager.facade';

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature(
			fromManager.MANAGER_FEATURE_KEY,
			fromManager.reducer
		),
		EffectsModule.forFeature([ManagerEffects])
	],
	providers: [ManagerFacade]
})
export class ManagerStateModule {}
