import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'checkIfArticleListVisible'
})
export class CheckIfArticleListVisibleEnumPipe implements PipeTransform {
	transform(
		array: {enabled: boolean; active: boolean}[],
		value: string
	): boolean {
		if (!array || array.length === 0 || !value) {
			return false;
		}
		if (value == 'INACTIVE') {
			return !!array.find(item => item.active === false);
		}
		if (value === 'ALL') {
			return array.every(item => item.enabled === true);
		}
		if (value === 'ANY') {
			return (
				array.some(item => item.enabled === true) &&
				array.some(item => item.enabled === false)
			);
		}
		return false;
	}
}
