import {Injectable} from '@angular/core';

import {select, Store} from '@ngrx/store';

import * as ManagerActions from './manager.actions';
import * as ManagerSelectors from './manager.selectors';
import {OrderStatus} from 'src/app/models/OrderStatus';
import {OrderType} from 'src/app/models/OrderType';
import Order from 'src/app/models/Order';
import {Table} from 'src/app/models/Table';
import Hours from 'src/app/models/Hours';
import {OrderPool} from 'src/app/models/OrderPool';

@Injectable()
export class ManagerFacade {
	readonly loaded$ = this.store.pipe(select(ManagerSelectors.loaded));
	readonly screensavers$ = this.store.pipe(
		select(ManagerSelectors.screensavers)
	);
	readonly tables$ = this.store.pipe(select(ManagerSelectors.tables));
	readonly categories$ = this.store.pipe(select(ManagerSelectors.categories));
	readonly venue$ = this.store.pipe(select(ManagerSelectors.venue));
	readonly optionGroups$ = this.store.pipe(
		select(ManagerSelectors.optionGroups)
	);

	readonly error$ = this.store.pipe(select(ManagerSelectors.error));
	public orders$ = this.store.pipe(select(ManagerSelectors.orders));
	readonly orderStatusFiler$ = this.store.pipe(
		select(ManagerSelectors.orderStatusFiler)
	);
	readonly filtersStockManager$ = this.store.pipe(
		select(ManagerSelectors.filtersStockManager)
	);
	readonly messageUpdate$ = this.store.pipe(
		select(ManagerSelectors.messageUpdate)
	);
	readonly errorSyncWolt$ = this.store.pipe(
		select(ManagerSelectors.errorSyncWolt)
	);
	readonly errorSyncUber$ = this.store.pipe(
		select(ManagerSelectors.errorSyncUber)
	);
	readonly errorSyncJet$ = this.store.pipe(
		select(ManagerSelectors.errorSyncJet)
	);
	readonly successSync$ = this.store.pipe(select(ManagerSelectors.successSync));
	readonly ordersPool$ = this.store.pipe(select(ManagerSelectors.ordersPool));
	readonly updateStatusLoading$ = this.store.pipe(
		select(ManagerSelectors.updateStatusLoading)
	);

	constructor(private store: Store) {}

	loadVenue(data: {venueId: string}) {
		this.store.dispatch(ManagerActions.loadVenue(data));
	}

	loadScreensavers(data: {venueId: string}) {
		this.store.dispatch(ManagerActions.loadScreensavers(data));
	}

	loadTables(data: {venueId: string}) {
		this.store.dispatch(ManagerActions.loadTables(data));
	}

	loadCategories(data: {venueId: string}) {
		this.store.dispatch(ManagerActions.loadCategories(data));
	}

	loadOptionGroups(data: {venueId: string}) {
		this.store.dispatch(ManagerActions.loadOptionGroups(data));
	}
	loadVenueData(data: {venueId: string}) {
		this.loadVenue(data);
		this.loadOptionGroups(data);
		// this.loadScreensavers(data);
		// this.loadTables(data);
		this.loadCategories(data);
	}
	loadOrders(data: {
		venue: string;
		status: OrderStatus[];
		orderTypes: OrderType[];
		from: string;
		to: string;
		promoCode: boolean;
	}) {
		this.store.dispatch(ManagerActions.loadOrders(data));
	}
	updateOrder(order: Order) {
		this.store.dispatch(ManagerActions.updateOrder({order}));
	}
	updateOrderStatus(order: Order, status: OrderStatus | null) {
		this.store.dispatch(ManagerActions.updateOrderStatus({order, status}));
	}

	setFilterOrderStatus(status: OrderStatus | null) {
		this.store.dispatch(ManagerActions.filterOrderStatusAdd({status}));
	}
	updateTable(table: Table) {
		this.store.dispatch(ManagerActions.updateTable({table}));
	}
	setStockManagerFilters(filters: {
		category: string;
		type: string;
		status: string;
		search: string;
	}) {
		this.store.dispatch(ManagerActions.setStockManagerFilters({filters}));
	}

	updateArticlesActive(data: {
		articles: string[];
		venue: string;
		action: string;
		isOption: boolean;
		snoozed?: string;
	}) {
		this.store.dispatch(ManagerActions.updateArticlesActive({data}));
	}
	updateArticleOptionActive(data: {
		articles: string[];
		venue: string;
		action: string;
		isOption: boolean;
	}) {
		this.store.dispatch(ManagerActions.updateArticlesActive({data}));
	}

	updateOpeningHours(data: {hours: Hours[]; venueId: string}) {
		this.store.dispatch(ManagerActions.updateOpeningHoursAction({data}));
	}
	updateListOfOptionArticles(data: {
		active: {
			articles: string[];
			venue: string;
			action: string;
			isOption: boolean;
		};
		disabled: {
			articles: string[];
			venue: string;
			action: string;
			isOption: boolean;
		};
		isActive: {
			articles: string[];
			venue: string;
			action: string;
			isOption: boolean;
		};
		inActive: {
			articles: string[];
			venue: string;
			action: string;
			isOption: boolean;
		};
	}) {
		this.store.dispatch(ManagerActions.updateArticlesOptionList({data}));
	}
	syncJet(venueId: string) {
		this.store.dispatch(ManagerActions.syncJet({data: {venueId}}));
	}
	syncWolt(venueId: string) {
		this.store.dispatch(ManagerActions.syncWolt({data: {venueId}}));
	}
	syncUber(venueId: string) {
		this.store.dispatch(ManagerActions.syncUber({data: {venueId}}));
	}
	killSuccess() {
		this.store.dispatch(ManagerActions.killSuccess());
	}
	updateOrderPool(orderPool: OrderPool, status: OrderStatus | null) {
		this.store.dispatch(
			ManagerActions.updateOrderPoolStatus({orderPool, status})
		);
	}
	addOrderPoolNew(orderPool: OrderPool) {
		console.log('ADD ORDER PULLL', orderPool);
		this.store.dispatch(ManagerActions.updateOrderPoolWithSocket({orderPool}));
	}
	loadOrdersPool(data: {
		venue: string;
		status: OrderStatus[];
		orderTypes: OrderType[];
		from: string;
		to: string;
		promoCode: boolean;
	}) {
		this.store.dispatch(ManagerActions.loadOrdersPool(data));
	}
}
