import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output
} from '@angular/core';
import Article from 'src/app/models/Article';

import {OptionGroup} from 'src/app/models/OptionGroup';
import {numberD, numberToCurrency} from 'src/app/services/order.service';

@Component({
	selector: 'app-article-item-with-groups-show',
	templateUrl: './article-item-with-groups-show.component.html',
	styleUrls: ['./article-item-with-groups-show.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArticleItemWithGroupsShowComponent implements OnInit {
	public optionGroup: any;
	numberD = numberD;
	numberToCurrency = numberToCurrency;
	@Input() set optionGroupValue(value: OptionGroup) {
		if (value) {
			this.optionGroup = value;
			console.log(value);
		}
	}
	public isOpened = false;
	@Output() selectedArticlesForChange = new EventEmitter<{
		article: Article;
		selected: boolean;
	}>();
	@Output() selectedArticlesIsActiveForChange = new EventEmitter<{
		article: Article;
		isActive: boolean;
	}>();
	constructor() {}

	ngOnInit() {}
	calcMinMaxPrice(optionGroup: OptionGroup) {
		if (!optionGroup.articles || optionGroup.articles.length === 0) {
			return '';
		}
		const listOfPrices = optionGroup.articles.map(it => numberD(it.price));
		const min = Math.min(...listOfPrices);
		const max = Math.max(...listOfPrices);
		return (
			'from ' +
			numberToCurrency(min, 'EUR') +
			' till ' +
			numberToCurrency(max, 'EUR')
		);
	}
	changeItem(event: boolean, item: Article) {
		console.log(event, item);
		this.selectedArticlesForChange.emit({
			article: item,
			selected: event
		});
	}
	changeItemActive(event: boolean, item: Article) {
		this.selectedArticlesIsActiveForChange.emit({
			article: item,
			isActive: event
		});
	}
}
