<nav class="horizontal-menu">
	<a mat-raised-button [routerLink]="'/' + urlLinkEnum.stockManager()">
		<mat-icon>bar_chart</mat-icon>
		{{ 'admin_console.stock_management' | translate }}
	</a>
	<!-- <a mat-raised-button>
		<mat-icon>settings</mat-icon>
		{{ 'admin_console.machine' | translate }}
	</a>
	<a mat-raised-button>
		<mat-icon>print</mat-icon>
		{{ 'admin_console.printer_settings' | translate }}
	</a> -->
	<a mat-raised-button [routerLink]="'/' + urlLinkEnum.openingHours()">
		<mat-icon>access_time</mat-icon>
		{{ 'admin_console.opening_hours' | translate }}
	</a>
	<!-- <a mat-raised-button>
		<mat-icon>euro_symbol</mat-icon>
		{{ 'admin_console.payments' | translate }}
	</a> -->
</nav>
