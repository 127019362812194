<div class="wrapper">
	<!-- <button (click)="stockManager()">Stock manager</button> -->
	<div class="header-block">
		<app-manager-header
			[venue]=" venue$  | async"
			[showBack]="true"
			[showChip]="true"
		></app-manager-header>
	</div>
	<app-table-list-draw></app-table-list-draw>
	<div class="main">
		<h1 class="title">{{"admin_console.title" | translate}}</h1>
		<app-admin-console-options></app-admin-console-options>
	</div>
</div>
