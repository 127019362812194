import {Injectable} from '@angular/core';
import {map} from 'rxjs';

import {NavigationService} from '../services/navigation.service';
import {AuthFacade} from '../store/auth-state/+state/auth.facade';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard {
	constructor(
		private navService: NavigationService,
		private authFacade: AuthFacade
	) {}
	// navigate to login page in case user token is not exist
	canActivate() {
		return this.authFacade.isLoggedIn$.pipe(
			map(isLoggedIn => {
				if (!isLoggedIn) {
					this.navService.login();
				}
				return isLoggedIn;
			})
		);
	}
}
