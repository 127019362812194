import {Component, OnInit} from '@angular/core';
import {map} from 'rxjs';

import {ManagerFacade} from 'src/app/store/manager/+state/manager.facade';

@Component({
	selector: 'app-table-list-draw',
	templateUrl: './table-list-draw.component.html',
	styleUrls: ['./table-list-draw.component.scss']
})
export class TableListDrawComponent implements OnInit {
	public tables$ = this.managerFacade.tables$.pipe(
		map(tables => {
			tables = tables.filter(
				table =>
					table.number !== 'preorder_table' && table.number !== 'system_table'
			);
			return tables;
		})
	);
	constructor(private managerFacade: ManagerFacade) {}

	ngOnInit() {}

	getStatus(status: string) {
		if (!status) {
			return '';
		}

		switch (status) {
			case 'OFFLINE':
				return 'OFFLINE';
			case 'OK':
				return 'OK';
			case 'IN_USE':
				return 'ONLINE';
			default:
				return '';
		}
	}
}
