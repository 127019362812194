<mat-accordion>
	<mat-expansion-panel (opened)="isOpened = true" (closed)="isOpened = false">
		<mat-expansion-panel-header>
			<div *ngIf="optionGroup" class="optiongroup_block">
				<div>
					<p>OptionGroup:</p>
					<h3>
						{{
							optionGroup.name
								? optionGroup.name.de || optionGroup.name.en
								: 'default name'
						}}
					</h3>
				</div>
				<div>
					<p>Price:</p>
					<h3>
						{{ calcMinMaxPrice(optionGroup) }}
					</h3>
				</div>
			</div>
		</mat-expansion-panel-header>
		@for (item of optionGroup.articles; track $index) {
			<div class="wrapper_names">
				<h4>
					{{ item.name.de || item.name.en }}
				</h4>
				<p>Price: {{ numberToCurrency(numberD(item.price), 'EUR') }}</p>
				<!-- <p class="slider">
					<span class="visible-state" [class.visible]="item.visible">{{
						item.visible ? 'visible' : 'invisible'
					}}</span>
					<mat-slide-toggle
						(ngModelChange)="changeItem($event, item)"
						[ngModel]="item.visible"
					></mat-slide-toggle>
				</p> -->
				<div class="show-active-block">
					<!-- <p class="slider">
						<span class="visible-state" [class.visible]="item.visible">{{
							item.visible ? 'visible' : 'invisible'
						}}</span>
						<mat-slide-toggle
							(ngModelChange)="changeItem($event, item)"
							[ngModel]="item.visible"
						></mat-slide-toggle>
					</p> -->
					<p class="slider">
						<span class="visible-state" [class.visible]="item.isActive">{{
							item.isActive ? 'active' : 'inactive'
						}}</span>
						<mat-slide-toggle
							(ngModelChange)="changeItemActive($event, item)"
							[ngModel]="item.isActive"
						></mat-slide-toggle>
					</p>
				</div>
			</div>
		}
	</mat-expansion-panel>
</mat-accordion>
