import {Component, OnDestroy} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NavigationService} from 'src/app/services/navigation.service';
import {AuthFacade} from 'src/app/store/auth-state/+state/auth.facade';
import {Actions, ofType} from '@ngrx/effects';
import {first, Subject, takeUntil} from 'rxjs';
import {
	loginFailure,
	loginSuccess
} from 'src/app/store/auth-state/+state/auth.actions';
import {HttpErrorResponse} from '@angular/common/http';
import {User} from 'src/app/models/User';
// import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.page.html',
	styleUrls: ['./login.page.scss']
})
export class LoginPage implements OnDestroy {
	public loginForm: FormGroup;
	private readonly onDestroy$ = new Subject<void>();

	constructor(
		private fb: FormBuilder,
		// private snackbar: SnackbarService,

		private navService: NavigationService,
		private authFacade: AuthFacade,
		private action$: Actions
	) {
		this.loginForm = this.fb.group({
			email: ['', [Validators.required, Validators.email]],
			password: ['', Validators.required]
		});
	}
	ngOnDestroy() {
		this.onDestroy$.next();
	}
	onSubmit() {
		this.authFacade.login(this.loginForm.value);
		this.action$
			.pipe(
				ofType(loginFailure, loginSuccess),
				first(),
				takeUntil(this.onDestroy$)
			)
			.subscribe(
				(action: {type: string; error?: HttpErrorResponse; reponse?: User}) => {
					console.log(action);
					if (action.type === loginSuccess.type) {
						return this.navService.managerPage();
					}
					if (action?.error?.error === 'Internal Server Error') {
						return this.loginForm.get('password')?.setErrors({
							incorrect: true
						});
					}
				}
			);
	}
}
