import {Component, OnInit} from '@angular/core';
import {combineLatest, filter, map, tap} from 'rxjs';
import {ManagerFacade} from 'src/app/store/manager/+state/manager.facade';
import {numberToCurrency} from 'src/app/services/order.service';
import {trigger, state, style, animate, transition} from '@angular/animations';
import {CategoryToArticleService} from 'src/app/services/categoryToArticles.service';
import {MatDialog} from '@angular/material/dialog';
import {ArticleModalComponent} from '../../article-modal/article-modal.component';
import {OptionGroup} from 'src/app/models/OptionGroup';
import ArticleCategory from 'src/app/models/ArticleCategory';

@Component({
	selector: 'app-stock-manager-draw-articles',
	templateUrl: './stock-manager-draw-articles.component.html',
	styleUrls: ['./stock-manager-draw-articles.component.scss'],
	animations: [
		trigger('drawCard', [
			state(
				'start',
				style({
					opacity: 0,
					transform: 'scale(0)'
				})
			),
			state(
				'end',
				style({
					opacity: 1,
					transform: 'scale(1)'
				})
			),
			transition('start => end', [animate('500ms ease-out')])
		])
	]
})
export class StockManagerDrawArticlesComponent implements OnInit {
	public numberToCurrency = numberToCurrency;
	animationState = 'start';
	public venueId: string;
	articles$ = combineLatest([
		this.managerFacade.categories$,
		this.managerFacade.filtersStockManager$,
		this.managerFacade.venue$,
		this.managerFacade.optionGroups$
	]).pipe(
		//TODO find correct venue to check with option GROUPS logic option groups is missing
		tap(() => {
			this.animationState = 'start';
		}),
		map(([categoriesValue, filters, venue, optionGroupsValue]) => {
			//TODO: logic is added but need refactor
			const copyOfOptionGroupsValue = JSON.parse(
				JSON.stringify(optionGroupsValue)
			) as OptionGroup[];
			let categories = JSON.parse(
				JSON.stringify(categoriesValue)
			) as ArticleCategory[];

			const optionGroupsMap = new Map(
				copyOfOptionGroupsValue.map(it => [it._id, it])
			);
			if (categories.length > 0 && copyOfOptionGroupsValue.length > 0)
				categories = categories.map(cat => {
					cat.articles = cat.articles?.map(art => {
						if (art.groups && art.groups.length > 0) {
							art.groups = art.groups.map((group: any) => {
								if (optionGroupsMap.has(group)) {
									group = optionGroupsMap.get(group);
								}
								return group;
							});
						}
						return art;
					});
					return cat;
				});

			this.venueId = venue?._id ?? '';
			const optionGroups = this.categoryService.mapOptionGroupsFromCategory(
				categories,
				filters.category
			);
			const listArticles = this.categoryService.mapArticlesFromCategory(
				categories,
				optionGroups,
				filters.category
			);
			const allArticles = this.categoryService.mapArticles(
				listArticles,
				categories,
				filters
			);

			return allArticles;
		}),
		filter(it => !!it),

		tap(() => {
			this.animationState = 'end';
		})
	);
	constructor(
		private managerFacade: ManagerFacade,
		private categoryService: CategoryToArticleService,
		public dialog: MatDialog
	) {}

	ngOnInit() {}
	sleep(ms: number) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}
	async changeItem(event: boolean, item) {
		if (this.venueId) {
			console.log('ngModel', event, item, this.venueId);
			this.managerFacade.updateArticlesActive({
				articles: [item.id],
				venue: this.venueId,
				action: event ? 'visible ' : 'hide',
				isOption: false
			});
			await this.sleep(1000);
		}
	}
	openDialog(item): void {
		const dialogRef = this.dialog.open(ArticleModalComponent, {
			data: {...item}
		});

		dialogRef.afterClosed().subscribe(
			(result: {
				data: {
					value: boolean;
					item: {
						active: boolean;
						constrainEnabled: boolean;
						enabled: boolean;
						id: string;
						parentName: string;
						price: {$numberDecimal: string};
						subName: string;
						type: string;
					};
				}[];
			}) => {
				if (result.data.length > 0) {
					const foundArticleToHide = result.data.filter(
						it => it.value == false
					);
					const foundArticleToShow = result.data.filter(it => it.value == true);

					if (foundArticleToHide.length > 0) {
						console.log(foundArticleToHide);

						this.managerFacade.updateArticlesActive({
							articles: foundArticleToHide.map(it => it.item.id),
							venue: this.venueId,
							action: 'hide',
							isOption: false
						});
					}
					if (foundArticleToShow.length > 0) {
						this.managerFacade.updateArticlesActive({
							articles: foundArticleToShow.map(it => it.item.id),
							venue: this.venueId,
							action: 'visible',
							isOption: false
						});
					}
				}
			}
		);
	}
}
