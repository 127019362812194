import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
	selector: 'app-article-modal',
	templateUrl: './article-modal.component.html',
	styleUrl: './article-modal.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArticleModalComponent {
	public listOfChange: {
		value: boolean;
		item: {
			active: boolean;
			constrainEnabled: boolean;
			enabled: boolean;
			id: string;
			parentName: string;
			price: {$numberDecimal: string};
			subName: string;
			type: string;
		};
	}[] = [];
	constructor(
		public dialogRef: MatDialogRef<ArticleModalComponent>,
		@Inject(MAT_DIALOG_DATA)
		public data: {
			name: any;
			plu: any;
			items: any[];
		}
	) {}

	close(): void {
		this.dialogRef.close();
	}
	save(): void {
		this.dialogRef.close({data: this.listOfChange});
	}
	changeValueFunc(data: {value: boolean; item: any}) {
		const foundIndex = this.listOfChange.findIndex(
			it => it.item.id === data.item.id
		);
		if (foundIndex < 0) {
			this.listOfChange.push(data);
		} else {
			this.listOfChange[foundIndex].value = data.value;
		}
	}
}
