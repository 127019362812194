<app-manager-header
	[venue]=" venue$  | async"
	[showBack]="true"
></app-manager-header>

<div class="main">
	<div class="questions">
		@for (question of questions; track $index) {
		<div
			class="question-item"
			[class.selected]="selectedQuestion === question"
			(click)="selectQuestion(question)"
		>
			<p class="question">
				<span class="number">{{question}}.</span>{{"faq.questions." + question +
				".question" | translate}}
			</p>
		</div>
		}
	</div>
	<div class="answers">
		<h2 class="title">
			{{"faq.questions." + selectedQuestion + ".question" | translate}}
		</h2>
		<p class="answer">
			{{"faq.questions." + selectedQuestion + ".answer" | translate}}
		</p>
	</div>
</div>
