import {Component, Input, OnInit} from '@angular/core';
import Order from 'src/app/models/Order';

@Component({
	selector: 'app-status-order-section',
	templateUrl: './status-order-section.component.html',
	styleUrls: ['./status-order-section.component.css']
})
export class StatusOrderSectionComponent implements OnInit {
	@Input() orders: Order[];
	constructor() {}

	ngOnInit() {}
}
