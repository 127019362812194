import {Pipe, PipeTransform} from '@angular/core';
import {numberToCurrency} from '../services/order.service';

@Pipe({
	name: 'minPrice'
})
export class MinPricePipe implements PipeTransform {
	transform(products: {price: number}[]): string {
		let price = 0;
		if (!products || products.length === 0) {
			price = 0;
		}
		price = products.reduce(
			(min, product) => (product.price < min ? product.price : min),
			products[0].price
		);
		return numberToCurrency(price, 'EUR');
	}
}
