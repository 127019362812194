import {ChangeDetectionStrategy, Component} from '@angular/core';
import {NAVIGATIONURLS} from 'src/app/services/navigation.service';

@Component({
	selector: 'app-admin-console-options',
	templateUrl: './admin-console-options.component.html',
	styleUrl: './admin-console-options.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminConsoleOptionsComponent {
	public urlLinkEnum = NAVIGATIONURLS;
}
