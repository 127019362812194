<div class="table_list">
	@for (table of tables$ | async; track table?._id) {
		<div
			class="wrapper"
			[class.green]="table?.status === 'OK'"
			[class.orange]="table?.status != 'OK' && table?.status != 'OFFLINE'"
		>
			<div class="cell">
				<div class="chip">{{ table.number }}</div>
			</div>
			<div class="cell">
				<div class="status">
					{{ getStatus(table.status) }}
				</div>
			</div>
		</div>
	}
</div>
