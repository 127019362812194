<div class="wrapper">
	<h1 class="title">
		{{ data.name }} <br /><span class="plu"> ({{ data.plu }}) </span>
	</h1>
	<div class="items">
		@for (item of data.items; track $index) {
			<app-article-modal-item
				[item]="item"
				(changeItemVisibilityEvent)="changeValueFunc($event)"
			></app-article-modal-item>
		}
	</div>
	<div class="buttons">
		<button mat-stroked-button (click)="close()">
			{{ 'article_modal.close' | translate }}
		</button>
		<button mat-flat-button color="accent" (click)="save()">
			{{ 'article_modal.confirm' | translate }}
		</button>
	</div>
</div>
