import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OrderStatusItemComponent} from './order-status-item/order-status-item.component';
import {StatusOrderSectionComponent} from './status-order-section/status-order-section.component';
import {ManagerOrderItemDrawComponent} from './manager-order-item-draw/manager-order-item-draw.component';
import {TableListDrawComponent} from './table-list-draw/table-list-draw.component';
import {OrderItemHeaderInfoComponent} from './order-item-header-info/order-item-header-info.component';
import {TranslateModule} from '@ngx-translate/core';
import {OrderItemStatusSectionComponent} from './order-item-status-section/order-item-status-section.component';
import {OrderItemArticlegroupDrawComponent} from './order-item-articlegroup-draw/order-item-articlegroup-draw.component';

import {ManagerHeaderComponent} from './venue-info/manager-header.component';
import {MaterialModule} from 'src/app/material-module/material-module';
import {FiltersChipComponent} from './filters-chip/filters-chip.component';
import {RouterModule} from '@angular/router';

@NgModule({
	imports: [CommonModule, MaterialModule, TranslateModule, RouterModule],
	declarations: [
		OrderStatusItemComponent,
		StatusOrderSectionComponent,
		ManagerOrderItemDrawComponent,
		ManagerHeaderComponent,
		FiltersChipComponent,
		OrderItemArticlegroupDrawComponent,
		TableListDrawComponent,
		OrderItemHeaderInfoComponent,
		OrderItemStatusSectionComponent
	],
	exports: [
		OrderStatusItemComponent,
		StatusOrderSectionComponent,
		ManagerOrderItemDrawComponent,
		ManagerHeaderComponent,
		FiltersChipComponent,
		OrderItemArticlegroupDrawComponent,
		TableListDrawComponent,
		OrderItemHeaderInfoComponent,
		OrderItemStatusSectionComponent
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ManagerComponentsModule {}
