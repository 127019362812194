import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {MaterialModule} from 'src/app/material-module/material-module';
import {ArticleModalComponent} from './article-modal.component';
import {ArticleModalItemComponent} from './article-modal-item/article-modal-item.component';
import {FormsModule} from '@angular/forms';

@NgModule({
	imports: [CommonModule, MaterialModule, TranslateModule, FormsModule],
	declarations: [ArticleModalComponent, ArticleModalItemComponent],
	exports: [ArticleModalComponent, ArticleModalItemComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ArticleModalModule {}
