import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output
} from '@angular/core';
import Order from 'src/app/models/Order';
import {OrderPool} from 'src/app/models/OrderPool';
import {STATUS_CHANGE} from 'src/app/services/order.service';

@Component({
	selector: 'app-manager-order-item-draw',
	templateUrl: './manager-order-item-draw.component.html',
	styleUrls: ['./manager-order-item-draw.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManagerOrderItemDrawComponent implements OnInit {
	orderValue: Order | OrderPool;
	@Input() set order(value: Order | OrderPool) {
		this.orderValue = value;
	}
	@Output() sendOrderStatusChange = new EventEmitter<{
		order: Order;
		status: STATUS_CHANGE;
	}>();
	@Output() sendOrderPoolStatusChange = new EventEmitter<{
		orderPool: OrderPool;
		status: STATUS_CHANGE;
	}>();
	public selectedOrderId: string = '';
	constructor(private el: ElementRef) {}

	ngOnInit() {}
	updateStatus(data: {order: Order; status: STATUS_CHANGE}) {
		this.sendOrderStatusChange.emit(data);
	}
	sendStatusOrderPoolFunc(data: {orderPool: OrderPool; status: STATUS_CHANGE}) {
		console.log('DATA', data);
		this.sendOrderPoolStatusChange.emit(data);
	}
	checkIfOrder(value: Order | OrderPool): value is Order {
		return (value as Order)?.orderedArticles != undefined;
	}
	checkIfOrderPool(value: Order | OrderPool): value is OrderPool {
		return (
			(value as OrderPool)?.dndCompany != undefined &&
			(value as OrderPool)?.orders != undefined
		);
	}
	emitSelectOrderToShow(event: {orderId: string}) {
		console.log(event.orderId);
		this.selectedOrderId = event.orderId;
		this.scrollToOrder(event.orderId);
	}
	scrollToOrder(orderId: string) {
		const element = this.el.nativeElement.querySelector(`#order-${orderId}`);
		if (element) {
			element.scrollIntoView({behavior: 'smooth', block: 'start'});
		}
	}
}
