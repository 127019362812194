<div class="order-card">
	<div class="order-header" *ngIf="orderValue">
		<app-order-item-header-info
			[orderValue]="orderValue"
		></app-order-item-header-info>
	</div>

	<app-order-item-status-section
		[orderValue]="orderValue"
		[updateStatusLoading]="updateStatusLoading"
		(sendStatusOrderPool)="sendStatusOrderPoolFunc($event)"
		(emitSelectOrderToShow)="emitSelectOrderToShow($event)"
		(sendOrderStatusChange)="updateStatus($event)"
	></app-order-item-status-section>
	@if (checkIfOrder(orderValue)) {
		<div class="order-details">
			<div class="order-item" *ngFor="let item of orderValue.orderedArticles">
				<app-order-item-articlegroup-draw
					[articleGroup]="item"
					[orderStatus]="orderValue.status"
				></app-order-item-articlegroup-draw>
			</div>
		</div>
	}
	@if (checkIfOrderPool(orderValue)) {
		<div class="order-pool-details">
			@for (orderFoodSpot of orderValue.orders; track orderFoodSpot._id) {
				<div [id]="'order-' + orderFoodSpot._id">
					<div class="order-title">
						<p [class.selected]="selectedOrderId === orderFoodSpot?._id">
							{{ orderFoodSpot.code }}
						</p>
					</div>
					<div
						class="order-item"
						*ngFor="let item of orderFoodSpot.orderedArticles"
					>
						<app-order-item-articlegroup-draw
							[articleGroup]="item"
							[orderStatus]="orderValue.orderStatus"
						></app-order-item-articlegroup-draw>
					</div>
				</div>
			}
		</div>
	}
</div>
