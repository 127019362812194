<div
	class="chip"
	(click)="selectStatusClick(option.status)"
	[ngStyle]="{border: option.selected ? '2px solid ' + option.color : ''}"
>
	<div
		class="quantity"
		[ngStyle]="{'background-color': option.color}"
		[class.selected]="option.selected"
	>
		{{ option.quantity }}
	</div>

	{{ 'status.' + option.status | translate }}
</div>
