import {Component, OnInit} from '@angular/core';
import {Subject, takeUntil} from 'rxjs';
import {ManagerFacade} from 'src/app/store/manager/+state/manager.facade';

@Component({
	selector: 'app-external-order-provider-status',
	templateUrl: './external-order-provider-status.component.html',
	styleUrl: './external-order-provider-status.component.scss'
})
export class ExternalOrderProviderStatusComponent implements OnInit {
	public venue$ = this.managerFacade.venue$;
	public statusUber$ = this.managerFacade.statusUber$;
	public statusWolt$ = this.managerFacade.statusWolt$;

	private readonly onDestroy$ = new Subject<void>();
	public woltEnabled = false;
	public uberEnabled = false;
	public isActiveWolt = false;
	public isActiveUber = false;

	constructor(private managerFacade: ManagerFacade) {}

	ngOnInit() {
		this.venue$.pipe(takeUntil(this.onDestroy$)).subscribe(venue => {
			if (venue?.externalOrderProviders) {
				const {uberEats, wolt} = venue.externalOrderProviders;
				if (uberEats?.enabled) {
					this.uberEnabled = uberEats.enabled;
					this.getStatusOfUber(venue._id);
				}

				if (wolt?.enabled) {
					this.woltEnabled = wolt.enabled;
					this.getStatusOfWolt(venue._id);
				}
			}
		});

		this.statusWolt$.pipe(takeUntil(this.onDestroy$)).subscribe(res => {
			this.isActiveWolt = res.isActive;
		});

		this.statusUber$.pipe(takeUntil(this.onDestroy$)).subscribe(res => {
			this.isActiveUber = res.isActive;
		});
	}

	public get woltStatusText(): string {
		return this.isActiveWolt ? 'Online' : 'Offline';
	}

	public get uberStatusText(): string {
		return this.isActiveUber ? 'Online' : 'Offline';
	}

	private getStatusOfUber(venueId: string) {
		this.managerFacade.statusUber(venueId);
	}

	private getStatusOfWolt(venueId: string) {
		this.managerFacade.statusWolt(venueId);
	}
}
