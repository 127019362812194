<div class="header-section" *ngIf="order">
	@if (order?.type === orderType.VIRTUAL) {
	} @else {
		<div class="order">
			<div
				class="preorder_type"
				*ngIf="type"
				[class.take_away]="type == 'take_away'"
				[class.delivery]="type == 'delivery'"
			>
				{{ 'type.' + type | translate }}
			</div>
			<div
				class="method"
				[class.cash]="paymentMethod === paymentMethodEnum.CASH"
			>
				<img [src]="'/assets/icons/' + paymentMethod + '.svg'" alt />
				<p>
					{{ 'payment_method.' + paymentMethod | translate }}
				</p>
			</div>
		</div>
	}
	<div
		class="info_order"
		[class.full-width]="order?.type === orderType.VIRTUAL"
	>
		@if (order.method == 'wolt') {
			<img [src]="'/assets/wolt.svg'" class="order_type" alt="type-icon" />
			<p class="type">
				{{ order.method | translate }}
			</p>
		} @else if (order.method == 'uber_eats') {
			<img [src]="'/assets/uber_eats.png'" class="order_type" alt="type-icon" />
			<p class="type">
				{{ order.method | translate }}
			</p>
		} @else if (order.method == 'jet') {
			<img
				[src]="'/assets/lieferando.png'"
				class="order_type"
				alt="type-icon"
			/>
			<p class="type">
				{{ order.method | translate }}
			</p>
		} @else if (order.type == orderType.VIRTUAL) {
			<img
				src="../../../../assets/pos.svg"
				class="order_type"
				alt="type-icon"
			/>
			<p class="type">
				{{ order.method | translate }}
			</p>
		} @else {
			<img
				[src]="'/assets/icons/' + order.type + '.svg'"
				class="order_type"
				alt="type-icon"
			/>

			<p class="type">
				{{ 'order_type.' + order.type | translate }}
			</p>
		}

		<p class="time">
			{{
				order.asap && !order.orderAt ? 'ASAP' : (order.orderAt | date: 'HH:mm')
			}}
		</p>

		@if (order.type !== orderType.VIRTUAL) {
			<p class="price" [ngStyle]="{color: getColorByMethod(paymentMethod)}">
				{{ numberToCurrency(order.totalPrice, order.currency) }}
			</p>
		} @else {
			<p class="price-virtual"></p>
		}
	</div>
</div>
<div class="header-section" *ngIf="orderPool">
	<div class="order">
		<div class="preorder_type">
			{{ 'type.foodspot' | translate }}
		</div>
		<div class="method">
			<img [src]="'/assets/icons/credit_card.svg'" alt />
			<p>
				{{ 'payment_method.credit_card' | translate }}
			</p>
		</div>
	</div>
	<div class="info_order">
		<img
			[src]="'/assets/icons/preorder.svg'"
			class="order_type"
			alt="type-icon"
		/>

		<p class="type">
			{{ 'type.foodspot' | translate }}
		</p>

		<p class="time">
			<!-- {{ order.asap ? 'ASAP' : (order.orderAt | date: 'HH:mm') }} -->
		</p>
		<p class="price">
			{{ numberToCurrency(orderPool?.totalPrice, 'EUR') }}
		</p>
		<!-- @if (order.type !== orderType.VIRTUAL) {
	
		} @else { -->
		<p class="price-virtual"></p>
		<!-- } -->
	</div>
</div>
