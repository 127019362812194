import {Component, OnInit} from '@angular/core';

@Component({
	selector: 'app-order-status-item',
	templateUrl: './order-status-item.component.html',
	styleUrls: ['./order-status-item.component.css']
})
export class OrderStatusItemComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
