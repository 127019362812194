import {Injectable} from '@angular/core';
import ArticleCategory from '../models/ArticleCategory';
import {OptionGroup} from '../models/OptionGroup';
import Article from '../models/Article';
import {StatusFormFilter} from '../components/stock-manager-components/stock-manager-header-filters/stock-manager-header-filters.component';
import {DisplayMode} from '../models/DisplayMode';
import {DisplayIdentifier} from '../models/DisplayIdentifier';
import {StockManagementType} from '../models/StockManagerType';

@Injectable({
	providedIn: 'root'
})
export class CategoryToArticleService {
	mapOptionGroupsFromCategory(
		categories: ArticleCategory[],
		filterCategory: string
	) {
		const distinctById = (items: OptionGroup[], key) => {
			const seen = new Set();
			return items.filter(item => {
				const keyValue = key(item);
				return seen.has(keyValue) ? false : seen.add(keyValue);
			});
		};
		if (filterCategory) {
			categories = categories.filter(cat => cat._id === filterCategory);
		}
		return distinctById(
			categories.flatMap(category =>
				(category?.articles ?? []).flatMap(article => article?.groups ?? [])
			),
			group => group._id
		);
	}
	mapArticlesFromCategory(
		categories: ArticleCategory[],
		optionGroups: OptionGroup[],
		filterCategory: string
	) {
		const distinctById = (items: Article[] | undefined, key) => {
			const seen = new Set();
			if (!items) {
				return [];
			}
			return items.filter(item => {
				const keyValue = key(item);
				return seen.has(keyValue) ? false : seen.add(keyValue);
			});
		};
		if (filterCategory) {
			categories = categories.filter(cat => cat._id === filterCategory);
		}
		// Main conversion logic
		const mainArticles = distinctById(
			categories.flatMap(category => category?.articles ?? []),
			(article: Article) => article._id
		);
		const filteredMainArticles = mainArticles.filter(
			article => !(article.number || '').startsWith('9999')
		);

		const additionalArticles = optionGroups
			.flatMap(og => {
				if (!og || !og._id) {
					return [];
				}
				const replacesMain = og.tags.some(
					tag => tag.identifier === 'replaces_main_article'
				);
				const parents = replacesMain
					? mainArticles.filter(article =>
							article.groups.some(group => group._id === og._id)
					  )
					: [];

				return distinctById(og.articles, article => article._id)
					.filter(article => !(article.optionNumber || '').startsWith('9999'))
					.map(article => {
						return {
							...article,
							linkedMains: parents,
							optionGroupName: og?.name?.de
						} as any;
					});
			})
			.filter(it => !!it);
		const allArticles = [...filteredMainArticles, ...additionalArticles];

		return allArticles;
	}

	mapArticles(
		articles: Article[],
		categories: ArticleCategory[],
		filters: {
			category: string;
			type: string;
			status: string;
			search: string;
		}
	) {
		const groupBy = (array, keyGetter) => {
			const map = new Map();
			array.forEach(item => {
				const key = keyGetter(item);
				const collection = map.get(key);
				if (!collection) {
					map.set(key, [item]);
				} else {
					collection.push(item);
				}
			});
			return map;
		};

		const allArticles = [...articles].map((it: any) => {
			const foundCategory = categories.find(
				cat => !!cat.articles?.find(art => art._id == it._id)
			);
			if (foundCategory) {
				it = {
					...it,
					categoryName: foundCategory.name.de
				};
			}
			return it;
		});

		const StockManagementType = {
			ARTICLE: 'ARTICLE',
			OPTION: 'OPTION'
		};

		const selectedStatus = filters.status;

		const stockManagementGroups = Array.from(
			groupBy(allArticles, it =>
				(it.number || it.optionNumber || '').replace('_nosync', '')
			)
		).map(([key, value]) => {
			const linkedMains = value
				.flatMap(it => it?.linkedMains)
				.filter(it => !!it)
				.filter((v, i, a) => a.findIndex(t => t?._id === v?._id) === i);
			const articleName =
				value.find(it => it.number) || linkedMains[0]?.name?.de;
			const option = value.find(it => it.optionNumber);
			const optionGroupName = option?.optionGroupName?.de;
			const optionName = option?.name?.de?.toString() ?? null;

			const name =
				articleName && optionName && articleName !== optionName
					? `${articleName}\n${optionGroupName}\n${optionName}`
					: articleName || `${optionGroupName}\n${optionName}`;
			return {
				name: name?.name?.de ?? name?.de ?? optionName,
				plu: key.replace('_nosync', ''),
				items: Array.from(
					new Set(
						value
							.flatMap(it => {
								const list: any[] = [];
								if (linkedMains.length) {
									list.push(
										...linkedMains.map(main => ({
											id: main._id,
											enabled: main.visible,
											active: it.isActive,
											parentName: main.categoryName.toString(),
											subName: main.name.de.toString(),
											price: main.price,
											type: StockManagementType.ARTICLE,
											constrainEnabled: main.isEffectiveActive
										}))
									);
								}
								const isOption = !!it.optionNumber;
								const type = isOption
									? StockManagementType.OPTION
									: StockManagementType.ARTICLE;
								if (isOption) {
								}
								const parentName = isOption
									? it?.optionGroupName
									: it?.categoryName;
								list.push({
									id: it._id,
									enabled: it.visible,
									active: it.isActive,
									parentName: parentName?.toString(),
									subName: it.name.de.toString(),
									price: it.price,
									type: type,
									constrainEnabled: it.isEffectiveActive
								});
								return list;
							})
							.map(item => item.id)
					)
				).map(id =>
					value
						.flatMap(it => {
							if (linkedMains.length) {
								return linkedMains.map(main => ({
									id: main._id,
									enabled: main.visible,
									active: it.isActive,
									parentName: main.categoryName.toString(),
									subName: main.name.de.toString(),
									price: main.price,
									type: StockManagementType.ARTICLE,
									constrainEnabled: main.isEffectiveActive
								}));
							}
							const isOption = !!it.optionNumber;
							const type = isOption
								? StockManagementType.OPTION
								: StockManagementType.ARTICLE;
							const parentName = isOption
								? it.optionGroupName
								: it.categoryName;
							return [
								{
									id: it._id,
									enabled: it.visible,
									active: it.isActive,
									parentName: parentName?.toString(),
									subName: it.name.de.toString(),
									price: it.price,
									type: type,
									constrainEnabled: it.isEffectiveActive
								}
							];
						})
						.find(item => item.id === id)
				)
			};
		});

		let filteredGroups = stockManagementGroups.filter(group => {
			switch (selectedStatus) {
				case StatusFormFilter.ALL:
					return true;
				case StatusFormFilter.ACTIVE:
					return group.items.some(item => item.enabled);
				case StatusFormFilter.NOT_ACTIVE:
					return group.items.some(item => !item.enabled);
				default:
					return true;
			}
		});
		if (filters.search) {
			filteredGroups = filteredGroups.filter(it => {
				return (
					it.name.toLowerCase().includes(filters.search.toLowerCase()) ||
					it.plu.toString().toLowerCase().includes(filters.search.toLowerCase())
				);
			});
		}
		return filteredGroups;
	}
	generateItems(
		categories: ArticleCategory[],
		filters: {
			category: string;
			type: string;
			status: StatusFormFilter;
			search: string;
		}
	) {
		const ogFilter = (optionGroup: OptionGroup) => {
			return !(
				(optionGroup.articles?.length === 1 &&
					optionGroup.limit === 1 &&
					optionGroup.requiredAmount === 1) ||
				optionGroup.displayMode === DisplayMode.DETAIL_OVERLAY ||
				optionGroup.displayIdentifiers?.includes(DisplayIdentifier.menu)
			);
		};
		if (filters.category) {
			categories = categories.filter(it => it._id == filters.category);
		}
		const allArticles = categories
			.flatMap(cat => {
				if (cat.articles) {
					return cat.articles.map(article => {
						return {
							...article,
							categoryId: cat._id,
							groups: article.groups.filter(ogFilter)
						};
					});
				} else {
					return [];
				}
			})
			.filter(
				(article, index, self) =>
					index === self.findIndex(a => a._id === article._id)
			);
		// let stockManagementGroups: {type: string, article: Article, parentName: string}[] = [];
		let stockManagementGroups: any[] = [];

		stockManagementGroups.push(
			...allArticles.map(article => ({
				type: StockManagementType.ARTICLE,
				article: article,
				parentName: article.categoryName ? article.categoryName.toString() : ''
			}))
		);

		// Extract option groups and filter them

		const optionGroups = categories
			.flatMap(category =>
				(category.articles ?? []).flatMap(article => article.groups)
			)
			.filter(
				(group, index, self) =>
					index === self.findIndex(g => g._id === group._id)
			)
			.filter(ogFilter);
		const options = optionGroups
			.flatMap(og => {
				return og.articles?.map(article => {
					const foundGroup = optionGroups.find(
						og =>
							(og.articles ?? []).find(a => a._id === article._id) !== undefined
					);
					let connectedArticles: any[] = [];
					if (foundGroup) {
						connectedArticles = allArticles
							.filter(article => article.groups.includes(foundGroup))
							.map(article => article.name);
					}

					return {
						parentName: foundGroup ? foundGroup.name.de.toString() : '',
						connectedArticles: connectedArticles,
						parentDescription: foundGroup
							? foundGroup.description.de
								? foundGroup.description.de.toString()
								: ''
							: '',
						article: article,
						type: StockManagementType.OPTION
					};
				});
			})
			.filter(opt => !!opt);

		stockManagementGroups.push(...options);
		if (filters.type) {
			stockManagementGroups = stockManagementGroups.filter(
				it => it.type === filters.type
			);
		}
		if (filters.status) {
			stockManagementGroups = stockManagementGroups.filter(it =>
				filters.status === StatusFormFilter.ACTIVE
					? it.article.visible
					: !it.article.visible || !it.article.isActive
			);
			console.log('stockManagementGroups', stockManagementGroups);
		}
		if (filters.search) {
			console.log(stockManagementGroups);
			stockManagementGroups = stockManagementGroups.filter(it => {
				return it.article.name
					? Object.values(it.article.name as Location).some(name =>
							name.toLowerCase().includes(filters.search.toLowerCase())
					  )
					: false;
			});
		}

		return stockManagementGroups;
	}
}
