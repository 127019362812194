import {ChangeDetectionStrategy, Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {
	NavigationService,
	NAVIGATIONURLS
} from 'src/app/services/navigation.service';
import {AuthFacade} from 'src/app/store/auth-state/+state/auth.facade';
import {AskPermissionDialogComponent} from '../ask-permission-dialog/ask-permission-dialog.component';

@Component({
	selector: 'app-admin-console-options',
	templateUrl: './admin-console-options.component.html',
	styleUrl: './admin-console-options.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminConsoleOptionsComponent {
	public urlLinkEnum = NAVIGATIONURLS;

	constructor(
		private authFacade: AuthFacade,
		private dialog: MatDialog,
		private navService: NavigationService,
		private translate: TranslateService
	) {}

	logout() {
		const dialogRef = this.dialog.open(AskPermissionDialogComponent, {
			data: {
				text: this.translate.instant('admin_console.logout_question')
			}
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.authFacade.logout();
				this.navService.login();
			}
		});
	}
}
