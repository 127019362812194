import {
	ChangeDetectionStrategy,
	Component,
	inject,
	OnInit
} from '@angular/core';
import {ManagerFacade} from 'src/app/store/manager/+state/manager.facade';

@Component({
	selector: 'app-admin-console',
	templateUrl: './admin-console.page.html',
	styleUrl: './admin-console.page.scss',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminConsolePage implements OnInit {
	private managerFacade = inject(ManagerFacade);
	public venue$ = this.managerFacade.venue$;

	ngOnInit(): void {}
}
