<mat-toolbar>
	<div class="header-content">
		<img
			(click)="back()"
			src="../../../../assets/tresen/smoothr_logo.svg"
			alt="Logo"
			class="logo"
		/>
		<div class="grey-block">
			<div class="title-block">
				<h2 class="title">{{ venue?.name }}</h2>
				@if (showChip) {
					<div class="chip-block">
						@for (chip of filtersChips$ | async; track $index) {
							<app-filters-chip
								[option]="chip"
								(selectStatus)="selectItem($event)"
							></app-filters-chip>
						}
					</div>
				}
			</div>
			<div class="icons">
				@if (showFaq) {
					<a [routerLink]="'/faq'">
						<img src="../../../../assets/tresen/faq.svg" alt="faq" />
					</a>
				}
				@if (showNotification) {
					<img
						src="../../../../assets/tresen/notifications.svg"
						alt="notifications"
					/>
				}
				@if (showMessages) {
					<img src="../../../../assets/tresen/messages.svg" alt="messages" />
				}
			</div>
			@if (!showBack) {
				<button
					class="menu-button"
					type="button"
					aria-label="Toggle sidenav"
					mat-icon-button
					(click)="onBurgerClick()"
				>
					<mat-icon color="primary">menu</mat-icon>
				</button>
			} @else {
				<button
					mat-stroked-button
					color="primary"
					class="back-button"
					(click)="navigateBack()"
				>
					{{ 'common.get_back' | translate }}
				</button>
			}
		</div>
	</div>
</mat-toolbar>
