@if (venue$ | async; as venue) {

<div class="main">
	<app-manager-header [venue]="venue" [showBack]="true"></app-manager-header>

	<div class="wrapper" [formGroup]="form">
		<h1 class="title">Opening Hours</h1>
		<div class="opening-hours" formArrayName="openingHours">
			@for (day of weekdays; track day; let index = $index) {
			<div class="item" [formGroupName]="index">
				<h2 class="day">{{day}}</h2>
				<div class="swiper" (click)="updateIsActiveForWeekday(index)">
					<div class="option closed">Closed</div>
					<div class="option open">Open</div>
					<div class="slider" [class.active]="getIsActive(index)">
						{{getIsActive(index) ? 'Open' : 'Closed'}}
					</div>
				</div>
				@if (getIsActive(index)) {
				<mat-form-field appearance="fill">
					<mat-label>Opened At</mat-label>
					<input matInput formControlName="openedAt" />
					<mat-error
						*ngIf="openingHours.controls.at(index)?.controls?.openedAt?.errors?.['invalidTime']"
					>
						The time format is hh:mm.
					</mat-error>
				</mat-form-field>
				<mat-form-field appearance="fill">
					<mat-label>Closed At</mat-label>
					<input matInput formControlName="closedAt" />
					<mat-error
						*ngIf="openingHours.controls.at(index)?.controls?.closedAt?.errors?.['invalidTime']"
					>
						The time format is hh:mm.
					</mat-error>
				</mat-form-field>
				}
			</div>
			}
		</div>
		<div class="buttons">
			<button mat-stroked-button (click)="close()">
				{{ 'article_modal.close' | translate }}
			</button>
			<button mat-flat-button color="accent" (click)="save()">
				{{ 'article_modal.confirm' | translate }}
			</button>
		</div>
	</div>
</div>
}
