<div
	*ngIf="articleGroup"
	class="wrapper"
	[class.green_light]="orderStatus == orderStatusEnum.DONE"
	[class.green]="orderStatus == orderStatusEnum.READY"
	[class.orange]="orderStatus == orderStatusEnum.IN_PREPARATION"
>
	<div class="article_name_block">
		<div class="quantity">
			x
			<p>{{ articleGroup.quantity }}</p>
		</div>
		<p class="name">
			{{ articleGroup.article.name.de }}
		</p>
	</div>
	@for (group of articleGroup.groups; track group?._id) {
		<div class="article_name_block">
			<div class="quantity">
				x
				<p>{{ group.quantity }}</p>
			</div>
			<p class="name">
				{{ group.article.name.de }}
			</p>
		</div>
	}
</div>
