import {Component, EventEmitter, Input, Output} from '@angular/core';
import * as moment from 'moment';
import Order from 'src/app/models/Order';
import {OrderPool} from 'src/app/models/OrderPool';
import {OrderStatus} from 'src/app/models/OrderStatus';
import {PreorderType} from 'src/app/models/PreorderType';
import {TerminalorderType} from 'src/app/models/TerminalorderType';
import {OrderService, STATUS_CHANGE} from 'src/app/services/order.service';

@Component({
	selector: 'app-order-item-status-section',
	templateUrl: './order-item-status-section.component.html',
	styleUrls: ['./order-item-status-section.component.scss']
})
export class OrderItemStatusSectionComponent {
	public order: Order;
	public orderPool: OrderPool;
	public orderStatusEnum = OrderStatus;
	public type: PreorderType | TerminalorderType | null;
	public orderPoolTotalProducts: number;
	public pt = PreorderType;
	@Input() updateStatusLoading: boolean | null;
	@Input() set orderValue(value: Order | OrderPool) {
		if (value) {
			if (this.checkIfOrder(value)) {
				this.order = value;
				console.log('ORDER VALUE', value);
				this.type = this.orderSevice.getOrderPreorderOrTerminalType(value);
			}
			if (this.checkIfOrderPool(value)) {
				this.orderPool = value;
				console.log(
					this.orderPool.orders.reduce((acc, redd) => {
						console.log(acc, redd.orderedArticles.length);
						return (
							acc +
							redd.orderedArticles
								.map(it => it.quantity)
								.reduce((a, b) => a + b, 0)
						);
					}, 0)
				);
				this.orderPoolTotalProducts = this.orderPool.orders.reduce(
					(acc, redd) => {
						return (
							acc +
							redd.orderedArticles
								.map(it => it.quantity)
								.reduce((a, b) => a + b, 0)
						);
					},
					0
				);
				console.log('orderPoolTotalProducts', this.orderPoolTotalProducts);
			}
		}
	}
	@Output() emitSelectOrderToShow = new EventEmitter<{
		orderId: string;
	}>();
	@Output() sendOrderStatusChange = new EventEmitter<{
		order: Order;
		status: STATUS_CHANGE;
	}>();
	@Output() sendStatusOrderPool = new EventEmitter<{
		orderPool: OrderPool;
		status: STATUS_CHANGE;
	}>();
	statusChangeENUM = STATUS_CHANGE;
	constructor(private orderSevice: OrderService) {}
	updateStatus(order: Order, status: STATUS_CHANGE) {
		this.sendOrderStatusChange.emit({order, status});
	}
	updateStatusPool(orderPool: OrderPool, status: STATUS_CHANGE) {
		console.log('STATUS POOOl', {orderPool, status});
		this.sendStatusOrderPool.emit({orderPool, status});
	}
	checkIfOrder(value: Order | OrderPool): value is Order {
		return value && (value as Order)?.orderedArticles != undefined;
	}
	checkIfOrderPool(value: Order | OrderPool): value is OrderPool {
		return value && (value as OrderPool)?.dndCompany != undefined;
	}
	selectOrderToShow(order: Order) {
		this.emitSelectOrderToShow.emit({
			orderId: order._id || ''
		});
	}
	formatTime(time: string | undefined) {
		if (!time) {
			return '';
		}
		return moment(time).format('HH:mm d/MM');
	}
}
