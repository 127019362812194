import {
	ChangeDetectionStrategy,
	Component,
	OnDestroy,
	type OnInit
} from '@angular/core';
import {
	AbstractControl,
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	ValidationErrors
} from '@angular/forms';
import * as moment from 'moment';
import {Subject, takeUntil} from 'rxjs';
import Hours from 'src/app/models/Hours';
import {NavigationService} from 'src/app/services/navigation.service';
import {ManagerFacade} from 'src/app/store/manager/+state/manager.facade';

interface OpeningHourControl {
	weekday: FormControl<number>;
	openedAt: FormControl<string>;
	closedAt: FormControl<string>;
	isActive: FormControl<boolean>;
}

@Component({
	selector: 'app-opening-hours',
	templateUrl: './opening-hours.page.html',
	styleUrl: './opening-hours.page.scss',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpeningHoursPage implements OnInit, OnDestroy {
	public venue$ = this.managerFacade.venue$;
	private readonly onDestroy$ = new Subject<void>();

	public venueId: string = '';
	public weekdays = [
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday',
		'Sunday'
	];
	form = this.fb.nonNullable.group({
		openingHours: this.fb.nonNullable.array<FormGroup<OpeningHourControl>>([])
	});

	constructor(
		private managerFacade: ManagerFacade,
		private fb: FormBuilder,
		private navService: NavigationService
	) {}

	ngOnInit(): void {
		this.venue$.pipe(takeUntil(this.onDestroy$)).subscribe(venue => {
			if (venue && venue._id) {
				this.venueId = venue._id;
			}
			if (venue && venue.openingHours) {
				this.convertToLocalTime(venue.openingHours);
			}
		});
	}
	ngOnDestroy(): void {
		this.onDestroy$.next();
	}
	private convertToLocalTime(openingHoursFromVenue: Hours[]) {
		const openingHoursFormArray = this.openingHours;
		this.weekdays.forEach((day, index) => {
			const hour = openingHoursFromVenue.find(hour => hour.weekday === index);
			const weekday = index;
			let openedAt = moment('00:00', 'HH:mm').format('HH:mm');
			let closedAt = moment('00:00', 'HH:mm').format('HH:mm');
			let isActive = false;
			if (hour) {
				const openedAtLocal = moment
					.utc(hour.openedAt, 'HH:mm')
					.local()
					.format('HH:mm');
				const closedAtLocal = moment
					.utc(hour.closedAt, 'HH:mm')
					.local()
					.format('HH:mm');

				openedAt = openedAtLocal;
				closedAt = closedAtLocal;
				isActive = true;
			}
			openingHoursFormArray.push(
				this.fb.nonNullable.group({
					weekday: new FormControl(weekday, {nonNullable: true}),
					openedAt: new FormControl(openedAt, {
						nonNullable: true,
						validators: [this.timeValidator]
					}),
					closedAt: new FormControl(closedAt, {
						nonNullable: true,
						validators: [this.timeValidator]
					}),
					isActive: new FormControl(isActive, {nonNullable: true})
				})
			);
		});

		this.form.markAllAsTouched();
	}

	get openingHours(): FormArray<FormGroup<OpeningHourControl>> {
		return this.form.controls.openingHours;
	}

	public getIsActive(index: number) {
		return this.openingHours.controls.at(index)?.controls.isActive.value;
	}

	public updateIsActiveForWeekday(weekday: number) {
		const openingHourGroup = this.openingHours.controls.find(
			control => control.controls.weekday.value === weekday
		);
		if (openingHourGroup) {
			openingHourGroup
				.get('isActive')
				?.setValue(!openingHourGroup.get('isActive')?.value);
		}
	}

	private timeValidator(control: AbstractControl): ValidationErrors | null {
		const value: string = control.value;
		const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;

		if (value && !timeRegex.test(value)) {
			return {invalidTime: true};
		}

		return null;
	}

	close(): void {
		this.navService.managerPage();
	}

	save(): void {
		if (!this.venueId) {
			return;
		}
		const openingHours = (
			JSON.parse(JSON.stringify(this.openingHours.value)) as {
				openedAt: string;
				closedAt: string;
				weekday: number;
				isActive: boolean;
			}[]
		)
			.filter(hour => hour.isActive)
			.map(hour => {
				hour.openedAt = moment(hour.openedAt, 'HH:mm').utc().format('HH:mm');
				hour.closedAt = moment(hour.closedAt, 'HH:mm').utc().format('HH:mm');

				return {
					weekday: hour.weekday,
					closedAt: hour.closedAt,
					openedAt: hour.openedAt
				};
			});
		this.managerFacade.updateOpeningHours({
			hours: openingHours,
			venueId: this.venueId
		});
	}
}
