import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import Article from 'src/app/models/Article';
// import {StockManagementType} from 'src/app/models/StockManagerType';

@Component({
	selector: 'app-article-with-groups-modal',
	templateUrl: './article-with-groups-modal.component.html',
	styleUrls: ['./article-with-groups-modal.component.scss']
})
export class ArticleWithGroupsModalComponent {
	public listOfChange: {
		selected: boolean;
		article: Article;
	}[] = [];
	public listOfChangeIsActive: {
		isActive: boolean;
		article: Article;
	}[] = [];
	constructor(
		public dialogRef: MatDialogRef<ArticleWithGroupsModalComponent>,
		@Inject(MAT_DIALOG_DATA)
		public data: any
	) {
		console.log('data', data);
	}

	close(): void {
		this.dialogRef.close();
	}
	save(): void {
		this.dialogRef.close({
			data: this.listOfChange,
			dataIsActive: this.listOfChangeIsActive
		});
	}

	selectedArticlesForChange(data: {article: Article; selected: boolean}) {
		const foundIndex = this.listOfChange.findIndex(
			it => it.article._id === data.article._id
		);
		if (foundIndex < 0) {
			this.listOfChange.push(data);
		} else {
			this.listOfChange[foundIndex].selected = data.selected;
		}
	}
	selectedArticlesIsActiveForChange(data: {
		article: Article;
		isActive: boolean;
	}) {
		const foundIndex = this.listOfChangeIsActive.findIndex(
			it => it.article._id === data.article._id
		);
		if (foundIndex < 0) {
			this.listOfChangeIsActive.push(data);
		} else {
			this.listOfChangeIsActive[foundIndex].isActive = data.isActive;
		}
	}
}
