<div class="container">
	<mat-card class="main-card">
		<mat-card-content class="card-content">
			<h3 class="title">Account Login</h3>
			<form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="login-form">
				<mat-form-field appearance="fill" class="full-width">
					<mat-label>Email address</mat-label>
					<input
						matInput
						formControlName="email"
						placeholder="Email"
						type="email"
					/>
				</mat-form-field>
				<mat-form-field appearance="fill" class="full-width">
					<mat-label>Password</mat-label>
					<input
						matInput
						formControlName="password"
						placeholder="Password"
						type="password"
					/>
				</mat-form-field>
				<div>
					<button
						mat-raised-button
						type="submit"
						class="submit-button full-width"
					>
						Log in
					</button>
				</div>
			</form>
		</mat-card-content>
		<div class="side-content">
			<div
				class="logo"
				style="
					background-image: url('https://dev-admin.smoothr.de/img/smoothr_logo.def9972e.svg');
				"
			></div>
			<p class="description">
				Smoothr is the shortcut for the digitization of your restaurant.
			</p>
		</div>
	</mat-card>
</div>
