import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject, filter, map, takeUntil} from 'rxjs';
import {AuthFacade} from 'src/app/store/auth-state/+state/auth.facade';
import {ManagerFacade} from 'src/app/store/manager/+state/manager.facade';

@Component({
	selector: 'app-faq',
	templateUrl: './faq.page.html',
	styleUrls: ['./faq.page.scss']
})
export class FaqPage implements OnInit, OnDestroy {
	public venue$ = this.managerFacade.venue$;
	public questions = Array.from({length: 7}, (v, i) => i + 1);
	public selectedQuestion = 1;
	private readonly onDestroy$ = new Subject<void>();

	constructor(
		private managerFacade: ManagerFacade,
		private authFacade: AuthFacade
	) {}

	ngOnInit() {
		this.authFacade.userData$
			.pipe(
				filter(userValue => !!userValue),
				map(userData => {
					return userData?.venues[0] ?? null;
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(venueId => {
				if (venueId) {
					this.managerFacade.loadVenueData({venueId});
				}
			});
	}
	ngOnDestroy(): void {
		this.onDestroy$.next();
	}
	public selectQuestion(question: number) {
		this.selectedQuestion = question;
	}
}
