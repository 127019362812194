<app-manager-header
	[venue]=" venue$  | async"
	[showBack]="true"
	[listenShowBack]="true"
	(backEvent)="listenGoBack()"
></app-manager-header>
<div class="wrapper">
	<div class="wrapper-header">
		<h1 class="title">{{"admin_console.stock_management" | translate}}</h1>
		<div class="button-wrappers">
			@if((venue$| async); as venueValue) { @if
			(venueValue.externalOrderProviders; as providerValue) { @if
			(providerValue.wolt.enabled) {
			<button
				class="wolt-btn"
				(click)="syncWolt(venueValue._id)"
				[disabled]="disabledWolt"
			>
				<h3>Sync with</h3>
				<img src="../../../../assets/wolt.svg" alt />
			</button>
			} @if (providerValue.jet.enabled) {
			<button
				class="jet-btn"
				(click)="syncJet(venueValue._id)"
				[disabled]="disableJet"
			>
				<h3>Sync with</h3>
				<img src="../../../../assets/lieferando.png" alt />
			</button>
			} @if (providerValue.uberEats.enabled) {
			<button
				class="uber-btn"
				(click)="syncUber(venueValue._id)"
				[disabled]="disableUber"
			>
				<h3>Sync with</h3>
				<img src="../../../../assets/uber_eats.png" alt />
			</button>
			} } }
		</div>
	</div>
	<app-stock-manager-header-filters> </app-stock-manager-header-filters>
	<app-stock-manager-draw-articles-new></app-stock-manager-draw-articles-new>
	<button class="scroll-to-top" (click)="scrollToTop()" *ngIf="isScrolled">
		↑ Top
	</button>
	<!-- <app-stock-manager-draw-articles></app-stock-manager-draw-articles> -->
</div>
