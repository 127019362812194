import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject, takeUntil, tap} from 'rxjs';
import {ManagerFacade} from 'src/app/store/manager/+state/manager.facade';
import {numberD, numberToCurrency} from 'src/app/services/order.service';

import {MatDialog} from '@angular/material/dialog';
// import { ArticleModalComponent } from '../../article-modal/article-modal.component';
import Article from 'src/app/models/Article';

import {ArticleWithGroupsModalComponent} from '../../article-with-groups-modal/article-with-groups-modal.component';
import {GroupedArticle} from 'src/app/models/GroupedArticle';
import {GroupedOptionArticle} from 'src/app/models/GroupedOptionArticle';

@Component({
	selector: 'app-stock-manager-draw-articles-new',
	templateUrl: './stock-manager-draw-articles-new.component.html',
	styleUrls: ['./stock-manager-draw-articles-new.component.scss']
})
export class StockManagerDrawArticlesNewComponent implements OnInit, OnDestroy {
	public numberToCurrency = numberToCurrency;
	public numberD = numberD;
	animationState = 'start';
	public venueId: string;
	private onDestroy$ = new Subject<void>();
	public canLoadMoreArticles = true;
	public canLoadMoreOptionArticles = true;

	articlesNew$: Observable<GroupedArticle[]>;
	optionArticlesNew$: Observable<GroupedOptionArticle[]>;

	constructor(
		private managerFacade: ManagerFacade,

		public dialog: MatDialog
	) {}

	ngOnInit() {
		this.managerFacade.venue$
			.pipe(
				tap(ven => {
					if (ven) this.venueId = ven._id;
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
		this.articlesNew$ = this.managerFacade.articlesNew$;
		this.optionArticlesNew$ = this.managerFacade.optionArticlesNew$;
		this.managerFacade.articlePagination$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(pagination => {
				this.canLoadMoreArticles = pagination.currentPage < pagination.pages;
			});

		this.managerFacade.optionAtriclePagination$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(pagination => {
				this.canLoadMoreOptionArticles =
					pagination.currentPage < pagination.pages;
			});
	}
	ngOnDestroy(): void {
		this.onDestroy$.next();
	}
	sleep(ms: number) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}
	async changeItem(event: boolean, item, isOption = false) {
		console.log('item', item);
		if (this.venueId) {
			this.managerFacade.updateArticlesActive({
				articles: [item._id],
				venue: this.venueId,
				action: event ? 'visible ' : 'hide',
				isOption: isOption
			});
			await this.sleep(1000);
		}
	}
	async changeItemActive(event: boolean, item, isOption = false) {
		if (this.venueId) {
			this.managerFacade.updateArticlesActive({
				articles: [item._id],
				venue: this.venueId,
				action: event ? 'activate ' : 'deactivate',
				isOption: isOption
			});
			await this.sleep(1000);
		}
	}
	openDialog(item: GroupedArticle): void {
		const dialogRef = this.dialog.open(ArticleWithGroupsModalComponent, {
			data: {...item}
		});
		dialogRef.afterClosed().subscribe(
			(result: {
				data: {
					article: Article;
					selected: boolean;
				}[];
				dataIsActive: {
					article: Article;
					isActive: boolean;
				}[];
			}) => {
				const foundArticleToHide = result.data.filter(
					it => it.selected == false
				);
				const foundArticleToShow = result.data.filter(
					it => it.selected == true
				);
				const foundArticlesToActive = result.dataIsActive.filter(
					it => it.isActive == true
				);
				const foundArticlesToInActive = result.dataIsActive.filter(
					it => it.isActive == false
				);
				// if (foundArticleToHide.length > 0 && foundArticleToShow.length > 0) {
				this.managerFacade.updateListOfOptionArticles({
					active: {
						articles: foundArticleToShow.map(it => it.article._id),
						venue: this.venueId,
						action: 'visible',
						isOption: true
					},
					disabled: {
						articles: foundArticleToHide.map(it => it.article._id),
						venue: this.venueId,
						action: 'hide',
						isOption: true
					},
					isActive: {
						articles: foundArticlesToActive.map(it => it.article._id),
						venue: this.venueId,
						action: 'activate',
						isOption: true
					},
					inActive: {
						articles: foundArticlesToInActive.map(it => it.article._id),
						venue: this.venueId,
						action: 'deactivate',
						isOption: true
					}
				});
				return;
			}
		);
	}

	loadMoreArticles() {
		this.managerFacade.loadMoreArticles(this.venueId);
	}

	loadMoreOptionArticles() {
		this.managerFacade.loadMoreOptionArticles(this.venueId);
	}
}
