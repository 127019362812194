<div class="wrapper">
	@for (article of articles$ | async; track article.name) {
		@if (article) {
			<mat-card
				[class.inactive]="!article.items[0].enabled"
				[@drawCard]="animationState"
				[class.no_active]="
					article.items | checkIfArticleListVisible: 'INACTIVE'
				"
			>
				<div class="item_style">
					<p class="number">
						{{ article?.plu }}
					</p>
					<p class="name">
						{{ article.name }}
					</p>
					<div class="price_with_toggle">
						<p class="price">
							{{ article.items | minPrice }}
						</p>
						@if (article.items.length > 1) {
							<div
								(click)="openDialog(article)"
								class="amount_block"
								[class.green]="article.items | checkIfArticleListVisible: 'ALL'"
								[class.orange]="
									article.items | checkIfArticleListVisible: 'ANY'
								"
							>
								<p class="variably">modifiers({{ article.items.length }})</p>
								<mat-icon>file_copy</mat-icon>
							</div>
						} @else {
							<p>
								<span
									class="visible-state"
									[class.visible]="article.items[0].enabled"
									>{{
										article.items[0].enabled ? 'visible' : 'invisible'
									}}</span
								>
								<mat-slide-toggle
									(ngModelChange)="changeItem($event, article.items[0])"
									[ngModel]="article.items[0].enabled"
								></mat-slide-toggle>
							</p>
						}
					</div>
				</div>
			</mat-card>
		}
	}
</div>
