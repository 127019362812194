import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {debounceTime, map, startWith, Subject, takeUntil} from 'rxjs';
import ArticleCategory from 'src/app/models/ArticleCategory';
import {StockManagementType} from 'src/app/models/StockManagerType';
import {ManagerFacade} from 'src/app/store/manager/+state/manager.facade';

export enum StatusFormFilter {
	ALL = '',
	ACTIVE = 'visible',
	NOT_ACTIVE = 'not_visible'
}

@Component({
	selector: 'app-stock-manager-header-filters',
	templateUrl: './stock-manager-header-filters.component.html',
	styleUrls: ['./stock-manager-header-filters.component.scss']
})
export class StockManagerHeaderFiltersComponent implements OnInit, OnDestroy {
	public formFilters: FormGroup;

	public categories$ = this.managerFacade.categories$.pipe(
		map(it => {
			let copyCategories = JSON.parse(JSON.stringify(it)) as ArticleCategory[];
			let result: {text: string; value: string}[] = [];
			result.push({
				text: this.translate.instant(
					'filters.category.all_categories'
				) as string,
				value: ''
			});
			result.push(
				...copyCategories.map(it => {
					return {
						text: it.name.de,
						value: it._id
					};
				})
			);

			return result;
		})
	);
	public status = [
		{
			value: '',
			text: this.translate.instant('filters.status.all_status')
		},
		{
			value: StatusFormFilter.ACTIVE,
			text: this.translate.instant('filters.status.active')
		},
		{
			value: StatusFormFilter.NOT_ACTIVE,
			text: this.translate.instant('filters.status.not_active')
		}
	];
	public type = [
		{
			value: '',
			text: this.translate.instant('filters.type.all')
		},
		{
			value: StockManagementType.ARTICLE,
			text: this.translate.instant('filters.type.article')
		},
		{
			value: StockManagementType.OPTION,
			text: this.translate.instant('filters.type.options')
		}
	];

	private destroy$ = new Subject<void>();
	private venue$ = this.managerFacade.venue$;
	private venueId: string = '';

	constructor(
		private fb: FormBuilder,
		private managerFacade: ManagerFacade,
		private translate: TranslateService
	) {
		this.formFilters = this.fb.group({
			categories: '',
			visibility: '',
			type: '',
			search: ''
		});
	}

	ngOnInit() {
		this.venue$.pipe(takeUntil(this.destroy$)).subscribe(value => {
			if (!value) return;
			this.venueId = value._id;
		});
		this.formFilters.valueChanges
			.pipe(
				startWith({categories: '', visibility: '', type: '', search: ''}),
				debounceTime(400),
				takeUntil(this.destroy$)
			)
			.subscribe(result => {
				if (!this.venueId) return;
				this.managerFacade.setStockManagerFilters(result);
				this.managerFacade.loadArticlesByType({...result}, this.venueId);
			});
	}
	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
