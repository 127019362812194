<div class="container">
	<div *ngIf="uberEnabled" class="provider">
		<img src="../assets/uber_eats.png" class="logo" height="15" />
		<mat-chip color="primary" class="status-chip">
			<div class="status-chip-content">
				<mat-icon [style.color]="isActiveUber ? 'green' : 'red'">
					{{ isActiveUber ? 'check_circle' : 'cancel' }}
				</mat-icon>
				{{ uberStatusText }}
			</div>
		</mat-chip>
	</div>
	<div *ngIf="woltEnabled" class="provider">
		<img src="../assets/wolt.svg" class="logo" height="18" />
		<mat-chip color="primary" class="status-chip">
			<div class="status-chip-content">
				<mat-icon [style.color]="isActiveWolt ? 'green' : 'red'">
					{{ isActiveWolt ? 'check_circle' : 'cancel' }}
				</mat-icon>
				{{ woltStatusText }}
			</div>
		</mat-chip>
	</div>
</div>
