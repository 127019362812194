export enum SocketRole {
	COUNTER_DISPLAY = 'COUNTER_DISPLAY',
	COUNTER_DISPLAY_MAIN = 'COUNTER_DISPLAY_MAIN',
	DRIVE_DISPLAY = 'DRIVE_DISPLAY',
	CUSTOMER_DISPLAY = 'CUSTOMER_DISPLAY',
	TERMINAL = 'TERMINAL',
	DRIVE_CHECKOUT = 'DRIVE_CHECKOUT',
	ANTARA = 'ANTARA',
	BACKEND_UI = 'BACKEND_UI',
	MONITORING_UI = 'MONITORING_UI',
	UNKNOWN = 'UNKNOWN'
}
