import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';

import {AuthFacade} from 'src/app/store/auth-state/+state/auth.facade';
import {filter, map, Subject, takeUntil, timer} from 'rxjs';

import {ManagerFacade} from 'src/app/store/manager/+state/manager.facade';

import {STATUS_CHANGE} from 'src/app/services/order.service';
import {NavigationService} from 'src/app/services/navigation.service';
import {UpdateExternalDeliveryDialogComponent} from './update-external-delivery-dialog/update-external-delivery-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
@Component({
	selector: 'app-stock-manager',
	templateUrl: './stock-manager.page.html',
	styleUrls: ['./stock-manager.page.scss']
})
export class StockManagerPage implements OnDestroy, OnInit {
	private readonly onDestroy$ = new Subject<void>();
	// private readonly stopListeningSocketForTable$ = new Subject<void>();
	public isScrolled = false;
	public disabledWolt = false;
	public disableUber = false;
	public disableJet = false;
	@HostListener('window:scroll', [])
	onWindowScroll() {
		const yOffset = window.pageYOffset;
		const scrollTopButtonVisibleOffset = 200;
		this.isScrolled = yOffset > scrollTopButtonVisibleOffset;
	}
	public venue$ = this.managerFacade.venue$;
	public statusChangeENUM = STATUS_CHANGE;
	constructor(
		private authFacade: AuthFacade,
		private managerFacade: ManagerFacade,
		// private socket: Socket,

		private navService: NavigationService,
		public dialog: MatDialog,
		private toaster: ToastrService
	) {}

	ngOnInit(): void {
		this.authFacade.userData$
			.pipe(
				filter(userValue => !!userValue),
				map(userData => {
					return userData?.venues[0] ?? null;
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(venueId => {
				if (venueId) {
					this.loadDataByUser(venueId);
				}
			});
		this.managerFacade.successSync$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(v => {
				console.log('SUCESS', v);
				if (v) {
					this.toaster.success('Sync started...', 'Success', {
						timeOut: 5000
					});
					this.managerFacade.killSuccess();
				}
			});
	}
	private loadDataByUser(venueId: string) {
		this.managerFacade.loadVenueData({venueId});
		this.loadOrdersWithDefaultConfig(venueId);
	}
	ngOnDestroy() {
		this.onDestroy$.next();
	}
	private loadOrdersWithDefaultConfig(venueId: string) {}

	stockManager() {
		this.navService.stockManager();
	}
	listenGoBack() {
		console.log('GO BACK');

		this.venue$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(v => {
				if (
					v?.externalOrderProviders?.jet?.enabled ||
					v?.externalOrderProviders?.wolt?.enabled ||
					v?.externalOrderProviders?.uberEats?.enabled
				) {
					const dialogRef = this.dialog.open(
						UpdateExternalDeliveryDialogComponent,
						{
							data: {
								...v
							}
						}
					);

					dialogRef.afterClosed().subscribe((result: {}) => {
						this.navService.managerPage();
					});
				} else {
					this.navService.managerPage();
				}
			})
			.unsubscribe();
		this.managerFacade.setStockManagerFilters({
			category: '',
			status: '',
			type: '',
			search: ''
		});
	}

	scrollToTop() {
		window.scrollTo({top: 0, behavior: 'smooth'});
	}
	syncUber(venueId: string) {
		this.disableUber = true;
		timer(5000)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(v => {
				this.disableUber = false;
			});
		this.managerFacade.syncUber(venueId);
		this.managerFacade.errorSyncUber$
			.pipe(takeUntil(timer(5000) || this.onDestroy$))
			.subscribe(error => {
				if (error) {
					this.toaster.error(error, 'Uber Sync Error', {
						timeOut: 5000
					});
				}
			});
	}
	syncJet(venueId: string) {
		this.disableJet = true;
		timer(5000).subscribe(v => {
			this.disableJet = false;
		});
		this.managerFacade.syncJet(venueId);
		this.managerFacade.errorSyncJet$
			.pipe(takeUntil(timer(5000) || this.onDestroy$))
			.subscribe(error => {
				if (error) {
					this.toaster.error(error, 'Jet Sync Error', {
						timeOut: 5000
					});
				}
			});
	}
	syncWolt(venueId: string) {
		this.disabledWolt = true;
		timer(5000).subscribe(v => {
			this.disabledWolt = false;
		});
		this.managerFacade.syncWolt(venueId);
		this.managerFacade.errorSyncWolt$
			.pipe(takeUntil(timer(5000) || this.onDestroy$))
			.subscribe(error => {
				if (error) {
					this.toaster.error(error, 'Wolt Sync Error', {
						timeOut: 5000
					});
				}
			});
	}
}
