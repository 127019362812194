import {Component, OnDestroy} from '@angular/core';
import {BehaviorSubject, Subject, filter, takeUntil} from 'rxjs';
import {NavigationService} from 'src/app/services/navigation.service';
import {environment} from 'src/environments/environment';

@Component({
	selector: 'app-pin-code',
	templateUrl: './pin-code.page.html',
	styleUrl: './pin-code.page.scss'
})
export class PinCodePage implements OnDestroy {
	public lengthOfCode = Array.from({length: 4}, (_, index) => index);
	public inputValue = new BehaviorSubject('');
	private readonly onDestroy$ = new Subject<void>();

	constructor(private navService: NavigationService) {
		this.inputValue.subscribe(v => {
			console.log(v);
		});
		this.inputValue
			.pipe(filter(it => it.length >= 4))
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(pinCode => {
				console.log('pinCode', pinCode);
				if (Number(pinCode) === environment.pinCode) {
					this.navService.adminConsole();
				} else {
					setTimeout(() => {
						this.inputValue.next('');
					}, 300);
				}
			});
	}

	public onNumber(event: Event) {
		console.log('event', (event.target as HTMLElement).innerText.split(''));
		const value = (event.target as HTMLElement).innerText.split('')[0];

		if (value && this.inputValue.getValue().length < this.lengthOfCode.length) {
			this.inputValue.next(this.inputValue.getValue() + value);
		}
	}
	ngOnDestroy(): void {
		this.onDestroy$.next();
	}
	getBack() {
		this.navService.managerPage();
	}
}
