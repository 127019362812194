import {Injectable} from '@angular/core';
import {OrderStatus} from '../models/OrderStatus';
import Order from '../models/Order';
import {PaymentMethod} from '../models/PaymentMethod';
export enum STATUS_CHANGE {
	PREV = 'prev',
	NEXT = 'next'
}

@Injectable({
	providedIn: 'root'
})
export class OrderService {
	getPreviousOrderStatus(currentStatus: OrderStatus | null) {
		switch (currentStatus) {
			case OrderStatus.IN_PREPARATION:
				return OrderStatus.AWAITING_CONFIRMATION;
			case OrderStatus.READY:
				return OrderStatus.IN_PREPARATION;
			case OrderStatus.DONE:
				return OrderStatus.READY;
			default:
				return null;
		}
	}
	getNextOrderStatus(currentStatus: OrderStatus | null) {
		switch (currentStatus) {
			case OrderStatus.AWAITING_CONFIRMATION:
				return OrderStatus.IN_PREPARATION;
			case OrderStatus.IN_PREPARATION:
				return OrderStatus.READY;
			case OrderStatus.READY:
				return OrderStatus.DONE;
			default:
				return null;
		}
	}
	applyNewStatusToOrder(statusChange: STATUS_CHANGE, order: Order) {
		switch (statusChange) {
			case STATUS_CHANGE.PREV:
				return this.getPreviousOrderStatus(order.status);
				break;
			case STATUS_CHANGE.NEXT:
				return this.getNextOrderStatus(order.status);
				break;
			default:
				return null;
				break;
		}
	}
	getOrderPreorderOrTerminalType(order: Order) {
		if (order.preorder) {
			return order.preorder.type;
		}
		if (order.terminalorder) {
			return order.terminalorder.type;
		} else {
			return null;
		}
	}
	getPaymentMethod(order: Order) {
		if (order.method === PaymentMethod.CASH) {
			return PaymentMethod.CASH;
		} else {
			return PaymentMethod.CREDIT_CARD;
		}
	}
}
export const numberToCurrency = (price: any, currencyCode: string): string => {
	if (!currencyCode) {
		return '' + numberD(price);
	}

	return numberD(price)
		.toLocaleString('de', {
			style: 'currency',
			currency: currencyCode
		})
		.replace(',', '.');
};
export const numberD = (x: any): number => {
	if (x === undefined) {
		return 0;
	}
	if (x === null) {
		return 0;
	}
	let candidate;
	if (x.$numberDecimal !== undefined) {
		candidate = +x.$numberDecimal;
	} else {
		candidate = +x;
	}
	if (isNaN(candidate)) {
		return 0;
	}
	return candidate;
};
