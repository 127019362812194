<div>
	<!-- @for (article of articles$ | async; track $index) { -->
	@if (articles$ | async; as objectValues) {
		@if (objectValues?.article; as mainArticles) {
			<h4 class="header_title">MAIN ARTICLES</h4>
			<div class="wrapper">
				@for (item of mainArticles; track item?.article?._id) {
					<mat-card
						[class.inactive]="!item.article.visible"
						[class.no_active]="!item.article.isActive"
					>
						<div class="item_style">
							<div class="wrapper_article">
								<p class="name_article">A</p>
								<div class="show-active-block">
									<!-- <p class="slider">
										<span
											class="visible-state"
											[class.visible]="item.article.visible"
											>{{
												item.article.visible ? 'visible' : 'invisible'
											}}</span
										>
										<mat-slide-toggle
											(ngModelChange)="changeItem($event, item.article)"
											[ngModel]="item.article.visible"
										></mat-slide-toggle>
									</p> -->
									<p class="slider">
										<span
											class="visible-state"
											[class.visible]="item.article.isActive"
											>{{ item.article.isActive ? 'active' : 'inactive' }}</span
										>
										<mat-slide-toggle
											(ngModelChange)="changeItemActive($event, item.article)"
											[ngModel]="item.article.isActive"
										></mat-slide-toggle>
									</p>
								</div>
							</div>
							<p class="name">
								{{ item.article.name.de || item.article.name.en }} (
								{{ item?.article?.number }})
							</p>

							<p *ngIf="item.article.priceByType.terminal.inside">
								{{
									numberToCurrency(
										numberD(item.article.priceByType.terminal.inside),
										'EUR'
									)
								}}
							</p>
							<p>
								{{ item.parentName }}
							</p>
							@if (
								item?.article?.groups?.length && item.article.groups.length > 0
							) {
								<div
									*ngIf="
										item?.article?.groups?.length &&
										item.article.groups.length > 0
									"
									class="amount_block"
									(click)="openDialog(item)"
								>
									<p class="variably">
										modifiers({{ item.article.groups.length }})
									</p>
									<mat-icon>file_copy</mat-icon>
								</div>
							} @else {
								<div class="amount_block">
									<p></p>
								</div>
							}
						</div>
					</mat-card>
				}
				<!-- @if (articlesToShow < mainArticles.length) {
					<div class="load_more">
						<button
							(click)="loadMoreArticles()"
							mat-stroked-button
							color="primary"
						>
							{{ 'common.load_more' | translate }}
						</button>
					</div>
				} -->
			</div>
		}
		@if (objectValues?.option; as optionArticles) {
			<h4 class="header_title">OPTION ARTICLES</h4>
			<div class="wrapper_options">
				@for (item of optionArticles; track item?.article?._id) {
					<mat-card
						[class.inactive]="!item.article.visible"
						[class.no_active]="!item.article.isActive"
					>
						<div class="item_style">
							<div class="wrapper_article">
								<p class="name_article">O</p>
								<!-- <p class="slider">
						<span
							class="visible-state"
							[class.visible]="item.article.visible">{{ item.article.visible ?
							'visible' : 'invisible' }}</span>
						<mat-slide-toggle
							(ngModelChange)="changeItem($event, item.article, true)"
							[ngModel]="item.article.visible"></mat-slide-toggle>
					</p> -->
								<div class="show-active-block">
									<!-- <p class="slider">
							<span
								class="visible-state"
								[class.visible]="item.article.visible">{{
								item.article.visible ? 'visible' : 'invisible'
								}}</span>
							<mat-slide-toggle
								(ngModelChange)="changeItem($event, item.article, true)"
								[ngModel]="item.article.visible"></mat-slide-toggle>
						</p> -->
									<p class="slider">
										<span
											class="visible-state"
											[class.visible]="item.article.isActive"
											>{{ item.article.isActive ? 'active' : 'inactive' }}</span
										>
										<mat-slide-toggle
											(ngModelChange)="
												changeItemActive($event, item.article, true)
											"
											[ngModel]="item.article.isActive"
										></mat-slide-toggle>
									</p>
								</div>
							</div>
							<p class="name">
								{{ item.article.name.de || item?.article?.name?.en }}
							</p>

							<p *ngIf="item.article.priceByType.terminal.inside">
								{{
									numberToCurrency(
										numberD(item.article.priceByType.terminal.inside),
										'EUR'
									)
								}}
							</p>
							<p>
								{{ item.parentName }}
							</p>
							<div class="connected_articles">
								@for (art of item.connectedArticles; track $index) {
									<p>{{ $index + 1 }}. {{ art.de }}</p>
								}
							</div>

							<p>Description: <br />{{ item.parentDescription }}</p>
						</div>
					</mat-card>
				}
				<!-- @if (optionsToShow < optionArticles.length) {
					<div class="load_more">
						<button
							(click)="loadMoreOptionArticles()"
							mat-stroked-button
							color="primary"
						>
							{{ 'common.load_more' | translate }}
						</button>
					</div>
				} -->
			</div>
		}
	}
</div>
