import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {User} from 'src/app/models/User';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	AUTH_URL = '/v1/auth';
	GENERAL_URL = '/v1/general';
	constructor(private http: HttpClient) {}
	login(data: {email: String; password: String}): Observable<User> {
		return this.http.post<User>(
			`${environment.apiUrl}${this.AUTH_URL}/venue/login`,
			data
		);
	}

	checkToken(refreshToken: string) {
		return this.http.post<User>(
			`${environment.apiUrl}${this.AUTH_URL}/refresh`,
			{
				refreshToken
			}
		);
	}
}
