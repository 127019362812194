import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output
} from '@angular/core';
import {numberToCurrency} from 'src/app/services/order.service';

@Component({
	selector: 'app-article-modal-item',
	templateUrl: './article-modal-item.component.html',
	styleUrl: './article-modal-item.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArticleModalItemComponent {
	@Input() item: {
		active: boolean;
		constrainEnabled: boolean;
		enabled: boolean;
		id: string;
		parentName: string;
		price: {$numberDecimal: string};
		subName: string;
		type: string;
	};
	public visible = false;
	@Output() changeItemVisibilityEvent = new EventEmitter<{
		value: boolean;
		item: {
			active: boolean;
			constrainEnabled: boolean;
			enabled: boolean;
			id: string;
			parentName: string;
			price: {$numberDecimal: string};
			subName: string;
			type: string;
		};
	}>();
	numberToCurrency = numberToCurrency;
	changeValue(
		value: boolean,
		item: {
			active: boolean;
			constrainEnabled: boolean;
			enabled: boolean;
			id: string;
			parentName: string;
			price: {$numberDecimal: string};
			subName: string;
			type: string;
		}
	) {
		this.changeItemVisibilityEvent.emit({value, item});
	}
}
