<div class="wrapper">
	<div class="section_filters" [formGroup]="formFilters">
		<div class="filter_wrapper_item">
			<ng-select
				[items]="categories$ | async"
				bindLabel="text"
				bindValue="value"
				formControlName="categories"
				[placeholder]="'filters.category.title' | translate"
				class="mb-4"
			>
			</ng-select>
		</div>
		<div class="filter_wrapper_item">
			<ng-select
				[items]="status"
				bindLabel="text"
				bindValue="value"
				formControlName="visibility"
				[placeholder]="'filters.status.title' | translate"
				class="mb-4"
			>
			</ng-select>
		</div>
		<div class="filter_wrapper_item">
			<ng-select
				[items]="type"
				bindLabel="text"
				bindValue="value"
				formControlName="type"
				[placeholder]="'filters.type.title' | translate"
				class="mb-4"
			>
			</ng-select>
		</div>
		<div class="filter_wrapper_item">
			<div class="block_search">
				<input
					type="text"
					formControlName="search"
					placeholder="Search by article, option and etc"
				/>
			</div>
		</div>
	</div>
</div>
