import {Component, Input, OnInit} from '@angular/core';
import ArticleGroup from 'src/app/models/ArticleGroup';
import {OrderStatus} from 'src/app/models/OrderStatus';

@Component({
	selector: 'app-order-item-articlegroup-draw',
	templateUrl: './order-item-articlegroup-draw.component.html',
	styleUrls: ['./order-item-articlegroup-draw.component.scss']
})
export class OrderItemArticlegroupDrawComponent implements OnInit {
	@Input() articleGroup: ArticleGroup;
	@Input() orderStatus: OrderStatus | null;

	public orderStatusEnum = OrderStatus;
	constructor() {}

	ngOnInit() {}
}
