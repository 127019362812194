<!-- <div class="item" [class.not_enabled]="item.enabled">
	<mat-grid-list cols="4" rowHeight="100%">
		<mat-grid-tile [colspan]="1">
			<div class="col">
				<span class="label">{{
					'article_modal.labels.category' | translate
				}}</span>
				<p class="info">{{ item.parentName }}</p>
			</div>
		</mat-grid-tile>
		<mat-grid-tile [colspan]="1">
			<div class="col">
				<span class="label">{{
					'article_modal.labels.article' | translate
				}}</span>
				<p class="info">{{ item.subName }}</p>
			</div>
		</mat-grid-tile>
		<mat-grid-tile [colspan]="1">
			<div class="col">
				<span class="label">{{
					'article_modal.labels.price' | translate
				}}</span>
				<p class="info">{{ numberToCurrency(item.price, 'EUR') }}</p>
			</div>
		</mat-grid-tile>
		<mat-grid-tile [colspan]="1">
			<div class="col">
				<mat-slide-toggle
					(ngModelChange)="changeValue($event, item)"
					[ngModel]="item.enabled"
				></mat-slide-toggle>
			</div>
		</mat-grid-tile>
	</mat-grid-list>
</div> -->

<mat-card [class.inactive]="!item.enabled" [class.no_active]="!item.active">
	<div class="item_style">
		<p class="name">
			{{ item.parentName }}
		</p>
		<div class="price_with_toggle">
			<p class="price">
				{{ numberToCurrency(item.price, 'EUR') }}
			</p>
			<p>
				<mat-slide-toggle
					(ngModelChange)="changeValue($event, item)"
					[ngModel]="item.enabled"
				></mat-slide-toggle>
			</p>
		</div>
	</div>
</mat-card>
