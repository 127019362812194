import {Component, OnDestroy, OnInit} from '@angular/core';

import {AuthFacade} from 'src/app/store/auth-state/+state/auth.facade';
import {combineLatest, filter, map, Subject, takeUntil} from 'rxjs';

import {ManagerFacade} from 'src/app/store/manager/+state/manager.facade';
import {OrderStatus} from 'src/app/models/OrderStatus';
import {OrderType} from 'src/app/models/OrderType';
import * as moment from 'moment';
import {Socket} from 'ngx-socket-io';
import {SocketRole} from 'src/app/models/SocketRoles';
import Order from 'src/app/models/Order';
import {OrderService, STATUS_CHANGE} from 'src/app/services/order.service';
import {Table} from 'src/app/models/Table';
import {NavigationService} from 'src/app/services/navigation.service';
import {ArticleModalComponent} from 'src/app/components/article-modal/article-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {OrderPool} from 'src/app/models/OrderPool';
import {PreorderType} from 'src/app/models/PreorderType';

@Component({
	selector: 'app-manger',
	templateUrl: './manger.page.html',
	styleUrls: ['./manger.page.scss']
})
export class ManagerPage implements OnDestroy, OnInit {
	private readonly onDestroy$ = new Subject<void>();
	private readonly stopListeningSocketForTable$ = new Subject<void>();
	public venue$ = this.managerFacade.venue$;

	public orders$ = combineLatest(
		this.managerFacade.orders$,
		this.managerFacade.ordersPool$,
		this.managerFacade.orderStatusFiler$
	).pipe(
		map(([ordersValue, orderPoolValue, status]) => {
			let orders = (JSON.parse(JSON.stringify(ordersValue)) as Order[]).filter(
				it => {
					if (it?.preorder?.type === PreorderType.FOOD_SPOT) {
						return false;
					} else {
						return true;
					}
				}
			);
			let orderPool = JSON.parse(JSON.stringify(orderPoolValue)) as OrderPool[];

			const statusOrder = [
				null,
				OrderStatus.AWAITING_CONFIRMATION,
				OrderStatus.IN_PREPARATION,
				OrderStatus.READY,
				OrderStatus.DONE
			];
			if (orders.length > 0)
				orders.sort((a, b) => {
					return (
						statusOrder.indexOf(a?.status ?? null) -
						statusOrder.indexOf(b?.status ?? null)
					);
				});
			if (orderPool.length > 0) {
				orderPool.sort((a, b) => {
					return (
						statusOrder.indexOf(a?.orderStatus ?? null) -
						statusOrder.indexOf(b?.orderStatus ?? null)
					);
				});
			}
			if (status != OrderStatus.DONE) {
				orders = orders.filter(it => it.status != OrderStatus.DONE);
				orderPool = orderPool.filter(it => it.orderStatus != OrderStatus.DONE);
			}

			if (!status) {
				orders = orders.filter(
					it =>
						it.status === OrderStatus.IN_PREPARATION ||
						it.status === OrderStatus.AWAITING_CONFIRMATION ||
						it.status === OrderStatus.READY
				);
				orderPool = orderPool.filter(
					it =>
						it.orderStatus === OrderStatus.IN_PREPARATION ||
						it.orderStatus === OrderStatus.AWAITING_CONFIRMATION ||
						it.orderStatus === OrderStatus.READY
				);

				return [...orderPool, ...orders].sort((a, b) => {
					return (
						statusOrder.indexOf(
							this.checkIfOrder(a)
								? a.status
								: this.checkIfOrderPool(a)
								  ? a.orderStatus
								  : null
						) -
						statusOrder.indexOf(
							this.checkIfOrder(b)
								? b.status
								: this.checkIfOrderPool(b)
								  ? b.orderStatus
								  : null
						)
					);
				});
			}
			orders = orders.filter(it => it.status == status);
			orderPool = orderPool.filter(it => it.orderStatus == status);

			return [...orderPool, ...orders].sort((a, b) => {
				return (
					statusOrder.indexOf(
						this.checkIfOrder(a)
							? a.status
							: this.checkIfOrderPool(a)
							  ? a.orderStatus
							  : null
					) -
					statusOrder.indexOf(
						this.checkIfOrder(b)
							? b.status
							: this.checkIfOrderPool(b)
							  ? b.orderStatus
							  : null
					)
				);
			});
		})
	);
	public statusChangeENUM = STATUS_CHANGE;
	constructor(
		private authFacade: AuthFacade,
		private managerFacade: ManagerFacade,
		private socket: Socket,
		private orderService: OrderService,
		private navService: NavigationService,
		public dialog: MatDialog
	) {}

	ngOnInit(): void {
		this.authFacade.userData$
			.pipe(
				filter(userValue => !!userValue),
				map(userData => {
					return userData?.venues[0] ?? null;
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(venueId => {
				if (venueId) {
					this.loadDataByUser(venueId);
				}
			});

		this.managerFacade.tables$
			.pipe(
				filter(device => device.length > 0),
				takeUntil(this.stopListeningSocketForTable$ || this.onDestroy$)
			)
			.subscribe(tables => {
				console.log('table', tables);
				if (tables.length > 0) {
					this.listenSocket(tables);
				}
			});

		this.orders$.subscribe(v => console.log(v));
	}
	private loadDataByUser(venueId: string) {
		this.managerFacade.loadVenueData({venueId});
		this.loadOrdersWithDefaultConfig(venueId);
	}
	ngOnDestroy() {
		this.onDestroy$.next();
	}
	private loadOrdersWithDefaultConfig(venueId: string) {
		this.managerFacade.loadOrders({
			venue: venueId,
			status: [
				OrderStatus.AWAITING_CONFIRMATION,
				OrderStatus.IN_PREPARATION,
				OrderStatus.READY,
				OrderStatus.DONE
			],
			orderTypes: [
				OrderType.TERMINAL,
				OrderType.STANDARD,
				OrderType.VIRTUAL,
				OrderType.PREORDER
			],
			from: moment().subtract(1, 'days').toString(),
			to: moment().add(2, 'days').toString(),
			promoCode: true
		});
		this.managerFacade.loadOrdersPool({
			venue: venueId,
			status: [OrderStatus.IN_PREPARATION, OrderStatus.READY, OrderStatus.DONE],
			orderTypes: [
				OrderType.TERMINAL,
				OrderType.STANDARD,
				OrderType.VIRTUAL,
				OrderType.PREORDER
			],
			from: moment().subtract(1, 'days').toString(),
			to: moment().add(2, 'days').toString(),
			promoCode: true
		});
		this.listenSocketOrders(venueId);
	}
	private listenSocketOrders(venueId: string) {
		console.log('here');
		this.socket.on('connect', () => {
			this.authenticateSocket({
				roles: [SocketRole.COUNTER_DISPLAY, SocketRole.COUNTER_DISPLAY_MAIN],
				venueIds: [venueId]
			});
		});

		this.socket.on('venue-' + venueId + '-orders', (data: any) => {
			if (data?.order && data?.type == 'update') {
				console.log('LISTEN', data);
				this.updateState(data.order);
			}
		});
		this.socket.on('venue-' + venueId + '-order-pools', (data: any) => {
			console.log('LISTEN pools', data);

			if (data?.orderPool && data?.type == 'updated') {
				console.log('LISTEN pools', data);
				this.managerFacade.addOrderPoolNew(data?.orderPool);
			}
		});
	}
	private updateState(order: Order) {
		this.managerFacade.updateOrder(order);
	}
	private authenticateSocket(data: {
		roles: SocketRole[];
		venueIds: string[];
	}): void {
		this.socket.emit('authenticate', data.roles, data.venueIds, (ack: any) => {
			console.log('Socket authenticated for ', ack, data);
		});
	}
	private listenSocket(tables: Table[]) {
		console.log('load Socket');
		// tables.forEach(it => {
		// 	this.socket.on(
		// 		`terminalStatus_${it.venue}_${it.number.replace(' ', '_')}`,
		// 		(data: any) => {
		// 			console.log('EMIT STATUS', data);
		// 			this.managerFacade.updateTable({...it, status: data ?? 'Failed'});
		// 		}
		// 	);
		// });
	}
	updateStatusOrderPool(data: {orderPool: OrderPool; status: STATUS_CHANGE}) {
		console.log(
			'HERE UPDATE POOL',
			data,
			this.orderService.getNextOrderStatus(data.orderPool.orderStatus)
		);
		if (!data || !data.orderPool || !data.status) {
			return;
		}
		switch (data.status) {
			case STATUS_CHANGE.PREV:
				this.managerFacade.updateOrderPool(
					data.orderPool,
					this.orderService.getPreviousOrderStatus(data.orderPool.orderStatus)
				);
				break;
			case STATUS_CHANGE.NEXT:
				this.managerFacade.updateOrderPool(
					data.orderPool,
					this.orderService.getNextOrderStatus(data.orderPool.orderStatus)
				);
				break;
			default:
				break;
		}
	}
	updateStatus(data: {order: Order; status: STATUS_CHANGE}) {
		if (!data || !data.order || !data.status) {
			return;
		}
		switch (data.status) {
			case STATUS_CHANGE.PREV:
				//TODO: fix when needed
				// this.managerFacade.updateOrderStatus(
				// 	data.order,
				// 	this.orderService.getPreviousOrderStatus(data.order.status)
				// );
				break;
			case STATUS_CHANGE.NEXT:
				this.managerFacade.updateOrderStatus(
					data.order,
					this.orderService.getNextOrderStatus(data.order.status)
				);
				break;
			default:
				break;
		}
	}
	stockManager() {
		this.navService.stockManager();
	}

	openDialog(): void {
		const dialogRef = this.dialog.open(ArticleModalComponent, {
			data: {articleName: 'aperol'}
		});

		dialogRef.afterClosed().subscribe(result => {
			console.log(result);
		});
	}
	checkIfOrder(value: Order | OrderPool): value is Order {
		return (value as Order)?.orderedArticles != undefined;
	}
	checkIfOrderPool(value: Order | OrderPool): value is OrderPool {
		return (value as OrderPool)?.dndCompany != undefined;
	}
}
