import {Component, EventEmitter, Input, Output} from '@angular/core';
import {OrderStatus} from 'src/app/models/OrderStatus';

@Component({
	selector: 'app-filters-chip',
	templateUrl: './filters-chip.component.html',
	styleUrl: './filters-chip.component.scss'
})
export class FiltersChipComponent {
	@Input() option: {
		quantity: number;
		color: string;
		status: OrderStatus | null;
		selected: boolean;
	};
	@Output() selectStatus = new EventEmitter<OrderStatus | null>();
	selectStatusClick(status: OrderStatus | null) {
		this.selectStatus.next(status);
	}
}
