<div
	*ngIf="order"
	class="block_info"
	[class.done]="order.status == orderStatusEnum.DONE"
	[class.green]="order.status == orderStatusEnum.READY"
	[class.orange]="order.status == orderStatusEnum.IN_PREPARATION"
>
	<p class="status_text">{{ 'status.' + order.status | translate }}</p>

	<div class="code_info">
		<button
			mat-icon-button
			[disabled]="updateStatusLoading"
			(click)="updateStatus(order, statusChangeENUM.PREV)"
		>
			<img
				[class.inactive]="order.status == orderStatusEnum.AWAITING_CONFIRMATION"
				src="/assets/icons/arrow_left.svg"
				alt
			/>
		</button>
		<div class="code-block">
			<p>
				{{ order.code }}
			</p>

			<ng-container *ngIf="order?.secondaryCode">
				<p class="secondary-code">{{ order.secondaryCode }}</p>
			</ng-container>
			<!-- @if (
			(order?.secondaryCode && order?.method == 'wolt') ||
			order?.method === 'uber_eats' ||
			order?.method === 'jet'
			) {
			<p class="secondary-code">{{ order.secondaryCode }}</p>
			} -->
		</div>
		<button
			mat-icon-button
			[disabled]="updateStatusLoading"
			(click)="updateStatus(order, statusChangeENUM.NEXT)"
		>
			<img
				[class.inactive]="order.status == orderStatusEnum.DONE"
				src="/assets/icons/arrow_right.svg"
				alt
			/>
		</button>
	</div>
	<div>
		@if (
			order.method == 'wolt' ||
			order.method === 'uber_eats' ||
			order.method === 'jet'
		) {
			<div class="user_info_external">
				<p *ngIf="order.orderAt">
					{{ 'user_info.' + type + '_date_time' | translate }}
				</p>
				<p *ngIf="order.orderAt">
					{{ order.orderAt | date: 'dd/MM/YYY HH:mm' }}
				</p>
				<p *ngIf="order?.preorder?.name">
					{{ order.preorder.name }}
				</p>
				<p *ngIf="order?.preorder?.phone">
					{{ order.preorder.phone }}
				</p>
				<p *ngIf="order.preorder?.type === pt.DELIVERY">
					{{ order?.preorder?.street }} {{ order?.preorder?.number }},
					{{ order?.preorder?.city }}
				</p>
			</div>
		}
		@if (
			order.preorder &&
			order.preorder.type &&
			!(
				order.method == 'wolt' ||
				order.method === 'uber_eats' ||
				order.method === 'jet'
			)
		) {
			<div class="user_info_external">
				<p *ngIf="order?.preorder?.name">
					{{ order.preorder.name }}
				</p>
				<p *ngIf="order?.preorder?.phone">
					{{ order.preorder.phone }}
				</p>
				<p *ngIf="order.preorder?.note || order.note">
					Note: {{ order.preorder.note }} {{ order.note }}
				</p>
				<p *ngIf="order.preorder?.type === pt.DELIVERY">
					{{ order?.preorder?.street }} {{ order?.preorder?.number }},
					{{ order?.preorder?.city }}
				</p>
			</div>
		}
		@if (order.terminalorder && order.terminalorder.type && order.note) {
			<div class="user_info_external">
				<p *ngIf="order?.note">Buzzer Code: {{ order.note }}</p>
			</div>
		}
	</div>
</div>
<div
	*ngIf="orderPool && orderPool?.orders"
	class="block_info"
	[class.done]="orderPool.orderStatus == orderStatusEnum.DONE"
	[class.green]="orderPool.orderStatus == orderStatusEnum.READY"
	[class.orange]="orderPool.orderStatus == orderStatusEnum.IN_PREPARATION"
>
	<p class="status_text">{{ 'status.' + orderPool.orderStatus | translate }}</p>

	<div class="code_info">
		<button
			mat-icon-button
			(click)="updateStatusPool(orderPool, statusChangeENUM.PREV)"
		>
			<img
				[class.inactive]="
					orderPool.orderStatus == orderStatusEnum.AWAITING_CONFIRMATION
				"
				src="/assets/icons/arrow_left.svg"
				alt
			/>
		</button>

		<p class="company">
			@if (orderPool.orders.length > 0) {
				Company:
				{{ orderPool.orders[0]?.dndCompanyCode ?? orderPool?.dndCompanyCode }}
			}
		</p>

		<button
			mat-icon-button
			(click)="updateStatusPool(orderPool, statusChangeENUM.NEXT)"
		>
			<img
				[class.inactive]="orderPool.orderStatus == orderStatusEnum.DONE"
				src="/assets/icons/arrow_right.svg"
				alt
			/>
		</button>
	</div>
	<div class="list-orders">
		<!-- @for (item of orderPool.orders; track $index) {
			<div class="order-item" (click)="selectOrderToShow(item)">
				{{ item.code }}
			</div>
		} -->
		Total Products: {{ orderPoolTotalProducts }}

		<ng-container *ngIf="orderPool?.estimatedAt">
			<br />
			Delivery Time: {{ formatTime(orderPool?.estimatedAt) }}
		</ng-container>
	</div>
	<div>
		<!-- @if (
			order.method == 'wolt' ||
			order.method === 'uber_eats' ||
			order.method === 'jet'
		) {
			<div class="user_info_external">
				<p *ngIf="order.orderAt">
					{{ 'user_info.' + type + '_date_time' | translate }}
				</p>
				<p *ngIf="order.orderAt">
					{{ order.orderAt | date: 'dd/MM/YYY HH:mm' }}
				</p>
				<p *ngIf="order?.preorder?.name">
					{{ order.preorder.name }}
				</p>
				<p *ngIf="order?.preorder?.phone">
					{{ order.preorder.phone }}
				</p>
			</div>
		} -->
		<!-- @if (
			order.preorder &&
			order.preorder.type &&
			!(
				order.method == 'wolt' ||
				order.method === 'uber_eats' ||
				order.method === 'jet'
			)
		) {
			<div class="user_info_external">
				<p *ngIf="order?.preorder?.name">
					{{ order.preorder.name }}
				</p>
				<p *ngIf="order?.preorder?.phone">
					{{ order.preorder.phone }}
				</p>
				<p *ngIf="order.preorder?.note || order.note">
					Note: {{ order.preorder.note }} || {{ order.note }}
				</p>
			</div>
		} -->
		<!-- @if (order.terminalorder && order.terminalorder.type && order.note) {
			<div class="user_info_external">
				<p *ngIf="order?.note">Buzzer Code: {{ order.note }}</p>
			</div>
		} -->
	</div>
</div>
