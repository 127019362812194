import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Venue} from 'src/app/models/Venue';
import {OptionGroup} from 'src/app/models/OptionGroup';
import ArticleCategory from 'src/app/models/ArticleCategory';
import Screensaver from 'src/app/models/Screensaver';
import {Table} from 'src/app/models/Table';
import {OrderStatus} from 'src/app/models/OrderStatus';
import {OrderType} from 'src/app/models/OrderType';
import Order from 'src/app/models/Order';
import {catchError, map, of} from 'rxjs';
import Hours from 'src/app/models/Hours';
import {OrderPool} from 'src/app/models/OrderPool';
// import { contMockPool } from './mochdata';

@Injectable({
	providedIn: 'root'
})
export class ManagerService {
	public GENERAL_URL = '/v1/general';
	public ORDER_URL = '/v2/order';
	public UBER_URL = '/v1/uber/eats';
	public JET_URL = '/v1/jet';
	public WOLT_URL = '/v1/wolt';
	constructor(private http: HttpClient) {}

	getVenueById(venueId: string) {
		return this.http.get<Venue>(
			`${environment.apiUrl}${this.GENERAL_URL}/venue/` + venueId
		);
	}

	// TODO: type: articles | options, visibility = visible | not_visible, page=1, limit=10, categories[]
	getArticles(data: {venueId: string; filters: any}) {
		let params = new HttpParams();

		if (data.filters.categories) {
			params = params.set('categories[]', data.filters.categories);
		}

		if (data.filters.visibility) {
			params = params.set('visibility', data.filters.visibility);
		}

		if (data.filters.type) {
			params = params.set('type', data.filters.type);
		}

		if (data.filters.search) {
			params = params.set('search', data.filters.search);
		}

		if (data.filters.page) {
			params = params.set('page', data.filters.page.toString());
		}

		params = params.set('limit', 30);

		params = params.set('sortField', 'createdAt');

		return this.http.get<any>(
			`${environment.apiUrl}${this.GENERAL_URL}/articlecategory/visibility/search/${data.venueId}`,
			{params}
		);
	}

	getScreenSaverByVenueId(venueId: string) {
		return this.http.get<Screensaver[]>(
			`${environment.apiUrl}${this.GENERAL_URL}/screensaver/` + venueId
		);
	}

	getOptionGroupsByVenue(venueId: string) {
		return this.http.get<OptionGroup[]>(
			`${environment.apiUrl}${this.GENERAL_URL}/articleoption/byvenue/` +
				venueId
		);
	}

	getTablesToVenue(venueId: string) {
		return this.http.get<Table[]>(
			`${environment.apiUrl}${this.GENERAL_URL}/venue/` + venueId + '/tables'
		);
	}

	getArticleCategoriesByVenueId(venueById: string) {
		return this.http.get<ArticleCategory[]>(
			`${environment.apiUrl}${this.GENERAL_URL}/articlecategory?venue=` +
				venueById +
				'&articles=true'
		);
	}

	getFilteredOrders(data: {
		venue: string;
		status: OrderStatus[];
		orderTypes: OrderType[];
		from: string;
		to: string;
		promoCode: boolean;
	}) {
		let params = new HttpParams({
			fromObject: {}
		});

		if (data) {
			if (data?.venue) {
				params = params.append('venue[]', data.venue);
			}
			if (data.status) {
				data.status.forEach((value: string) => {
					params = params.append(`status[]`, value.toString());
				});
			}
			if (data.orderTypes) {
				data.orderTypes.forEach((type: string) => {
					params = params.append(`type[]`, type.toString());
				});
			}
			if (data.from) {
				params = params.append('from', data.from);
			}
			if (data.to) {
				params = params.append('to', data.to);
			}
			if (data.promoCode) {
				params = params.append('promoCode', data.promoCode);
			}
		}
		return this.http.get<Order[]>(
			`${environment.apiUrl}${this.ORDER_URL}/filtered`,
			{params}
		);
	}
	updateOrderStatus(order: Order) {
		return this.http.patch<Order>(
			`${environment.apiUrl}${this.ORDER_URL}/${order._id}`,
			{status: order.status}
		);
	}
	updateArticleActive(data: {
		articles: string[];
		venue: string;
		action: string;
	}) {
		return this.http
			.patch(
				`${environment.apiUrl}${this.GENERAL_URL}/articlecategory/venue/${data.venue}/articles/${data.action}`,
				{
					articles: data.articles,
					venue: data.venue
				},
				{observe: 'response', responseType: 'text'}
			)
			.pipe(
				map(response => {
					if (response.status === 200) {
						return {status: response.status};
					} else {
						throw new Error(`Unexpected status: ${response.status}`);
					}
				}),
				catchError(error => {
					return of({status: error.status || 500});
				})
			);
	}

	updateOptionArticleActive(data: {
		articles: string[];
		venue: string;
		action: string;
		isOption: boolean;
	}) {
		if (data.articles.length == 0) {
			return of({status: 204});
		}
		return this.http
			.patch(
				`${environment.apiUrl}${this.GENERAL_URL}/articleoption/venue/${data.venue}/articles/${data.action}`,
				{
					articles: data.articles,
					venue: data.venue
				},
				{observe: 'response', responseType: 'text'}
			)
			.pipe(
				map(response => {
					if (response.status === 200) {
						return {status: response.status, data};
					} else {
						throw new Error(`Unexpected status: ${response.status}`);
					}
				}),
				catchError(error => {
					return of({status: error.status || 500});
				})
			);
	}

	updateOpeningHours(data: {hours: Hours[]; venueId: string}) {
		return this.http.patch(
			`${environment.apiUrl}${this.GENERAL_URL}/venue/${data.venueId}/openinghours`,
			data.hours
		);
	}
	syncJet(venueId: string) {
		return this.http.put(
			`${environment.apiUrl}${this.JET_URL}/foodcards/venues/${venueId}`,
			{}
		);
	}
	syncWolt(venueId: string) {
		return this.http.put(
			`${environment.apiUrl}${this.WOLT_URL}/foodcards/venues/${venueId}`,
			{}
		);
	}
	syncUber(venueId: string) {
		return this.http.put(
			`${environment.apiUrl}${this.UBER_URL}/eats/menus/venues/${venueId}`,
			{}
		);
	}
	statusUber(venueId: string) {
		return this.http.get(
			`${environment.apiUrl}${this.UBER_URL}/venues/${venueId}/status`
		);
	}
	statusWolt(venueId: string) {
		return this.http.get(
			`${environment.apiUrl}${this.WOLT_URL}/venues/${venueId}/status`
		);
	}
	getOrderPools(data: {
		venue: string;
		status: OrderStatus[];
		orderTypes: OrderType[];
		from: string;
		to: string;
		promoCode: boolean;
	}) {
		let params = new HttpParams({
			fromObject: {}
		});

		if (data) {
			if (data?.venue) {
				params = params.append('venue', data.venue);
			}
			if (data.status) {
				data.status.forEach((value: string) => {
					params = params.append(`orderStatus[]`, value.toString());
				});
			}
			// if (data.orderTypes) {
			// 	data.orderTypes.forEach((type: string) => {
			// 		params = params.append(`type[]`, type.toString());
			// 	});
			// }
			if (data.from) {
				params = params.append('from', data.from);
			}
			if (data.to) {
				params = params.append('to', data.to);
			}
			// if (data.promoCode) {
			// 	params = params.append('promoCode', data.promoCode);
			// }
		}
		// if (true) {
		// 	return of(contMockPool);
		// }
		return this.http.get<OrderPool[]>(
			`${environment.apiUrl}${this.ORDER_URL}/pool`,
			{params}
		);
	}
	updateOrderPoolStatus(order: OrderPool, status: OrderStatus | null) {
		return this.http.patch<OrderPool>(
			`${environment.apiUrl}${this.ORDER_URL}/pool/${order._id}/status`,
			{status: status}
		);
	}
}
