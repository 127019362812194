<div>
	@if (articlesNew$ | async; as articles) {
		@if (articles.length) {
			<h4 class="header_title">MAIN ARTICLES</h4>
			<div class="wrapper">
				@for (item of articles; track item._id) {
					<mat-card [class.inactive]="!item.visible">
						<div class="item_style">
							<div class="wrapper_article">
								<p class="name_article">A</p>
								<div class="show-active-block">
									<p class="slider">
										<span
											class="visible-state"
											[class.visible]="item.visible"
											>{{ item.visible ? 'visible' : 'invisible' }}</span
										>
										<mat-slide-toggle
											(ngModelChange)="changeItem($event, item)"
											[ngModel]="item.visible"
										></mat-slide-toggle>
									</p>
									<!-- <p class="slider">
										<span
											class="visible-state"
											[class.visible]="item.article.isActive"
											>{{ item.article.isActive ? 'active' : 'inactive' }}</span
										>
										<mat-slide-toggle
											(ngModelChange)="changeItemActive($event, item.article)"
											[ngModel]="item.article.isActive"
										></mat-slide-toggle>
									</p> -->
								</div>
							</div>
							<p class="name">
								{{ item.name.de || item.name.en }} ( {{ item?.number }})
							</p>

							<p>
								{{ item.categoryName.de || item.categoryName.en }}
							</p>

							@if (item?.groups?.length && item.groups.length > 0) {
								<div
									*ngIf="item?.groups?.length && item.groups.length > 0"
									class="amount_block"
									(click)="openDialog(item)"
								>
									<p class="variably">modifiers({{ item.groups.length }})</p>
									<mat-icon>file_copy</mat-icon>
								</div>
							} @else {
								<div class="amount_block">
									<p></p>
								</div>
							}
						</div>
					</mat-card>
				}
				@if (canLoadMoreArticles) {
					<div class="load_more">
						<button
							(click)="loadMoreArticles()"
							mat-stroked-button
							color="primary"
						>
							{{ 'common.load_more' | translate }}
						</button>
					</div>
				}
			</div>
		}
	}
	@if (optionArticlesNew$ | async; as optionArticles) {
		@if (optionArticles.length) {
			<h4 class="header_title">OPTION ARTICLES</h4>
			<div class="wrapper_options">
				@for (item of optionArticles; track item._id) {
					<mat-card [class.inactive]="!item.visible">
						<div class="item_style">
							<div class="wrapper_article">
								<p class="name_article">O</p>
								<!-- <p class="slider">
              <span
                class="visible-state"
                [class.visible]="item.article.visible">{{ item.article.visible ?
                'visible' : 'invisible' }}</span>
              <mat-slide-toggle
                (ngModelChange)="changeItem($event, item.article, true)"
                [ngModel]="item.article.visible"></mat-slide-toggle>
            </p> -->
								<div class="show-active-block">
									<p class="slider">
										<span
											class="visible-state"
											[class.visible]="item.visible"
											>{{ item.visible ? 'visible' : 'invisible' }}</span
										>
										<mat-slide-toggle
											(ngModelChange)="changeItem($event, item, true)"
											[ngModel]="item.visible"
										></mat-slide-toggle>
									</p>
									<!-- <p class="slider">
                      <span
                        class="visible-state"
                        [class.visible]="item.article.isActive"
                        >{{ item.article.isActive ? 'active' : 'inactive' }}</span
                      >
                      <mat-slide-toggle
                        (ngModelChange)="
                          changeItemActive($event, item.article, true)
                        "
                        [ngModel]="item.article.isActive"
                      ></mat-slide-toggle>
                    </p> -->
								</div>
							</div>
							<p class="name">
								{{ item.name.de || item?.name?.en }}
							</p>

							<p>
								{{ numberToCurrency(numberD(item.price), 'EUR') }}
							</p>
							<p>
								{{ item.groupName.de || item.groupName.en }}
							</p>
							<div class="connected_articles">
								@for (art of item.connectedArticles.articles; track $index) {
									<p>{{ $index + 1 }}. {{ art.name.de || art.name.en }}</p>
								}
							</div>

							<p>
								Description: <br />{{
									item.description.de || item.description.de
								}}
							</p>
						</div>
					</mat-card>
				}
				@if (canLoadMoreOptionArticles) {
					<div class="load_more">
						<button
							(click)="loadMoreOptionArticles()"
							mat-stroked-button
							color="primary"
						>
							{{ 'common.load_more' | translate }}
						</button>
					</div>
				}
			</div>
		}
	}
</div>
