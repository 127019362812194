import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {Subject, takeUntil, timer} from 'rxjs';
import {Venue} from 'src/app/models/Venue';
import {ManagerFacade} from 'src/app/store/manager/+state/manager.facade';

@Component({
	selector: 'app-update-external-delivery-dialog',
	templateUrl: './update-external-delivery-dialog.component.html',
	styleUrls: ['./update-external-delivery-dialog.component.scss']
})
export class UpdateExternalDeliveryDialogComponent
	implements OnInit, OnDestroy
{
	public disabledWolt = false;
	public disableUber = false;
	public disableJet = false;
	private readonly onDestroy$ = new Subject<void>();

	constructor(
		public dialogRef: MatDialogRef<UpdateExternalDeliveryDialogComponent>,
		@Inject(MAT_DIALOG_DATA)
		public data: Venue,
		public managerFacade: ManagerFacade,
		private toaster: ToastrService
	) {}

	ngOnInit() {}
	ngOnDestroy(): void {
		this.onDestroy$.next();
	}
	syncUber() {
		this.disableUber = true;
		timer(5000)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(v => {
				this.disableUber = false;
			});
		this.managerFacade.syncUber(this.data._id);
		this.managerFacade.errorSyncUber$
			.pipe(takeUntil(timer(5000)))
			.subscribe(error => {
				if (error) {
					this.toaster.error(error, 'Uber Sync Error', {
						timeOut: 5000
					});
				}
			});
	}
	syncJet() {
		this.disableJet = true;
		timer(5000)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(v => {
				this.disableJet = false;
			});
		this.managerFacade.syncJet(this.data._id);
		this.managerFacade.errorSyncJet$
			.pipe(takeUntil(timer(5000)))
			.subscribe(error => {
				if (error) {
					this.toaster.error(error, 'Jet Sync Error', {
						timeOut: 5000
					});
				}
			});
	}
	syncWolt() {
		this.disabledWolt = true;
		timer(5000)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(v => {
				this.disabledWolt = false;
			});
		this.managerFacade.syncWolt(this.data._id);
		this.managerFacade.errorSyncWolt$
			.pipe(takeUntil(timer(5000)))
			.subscribe(error => {
				if (error) {
					this.toaster.error(error, 'Wolt Sync Error', {
						timeOut: 5000
					});
				}
			});
	}
	close() {
		this.dialogRef.close();
	}
}
