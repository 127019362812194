import {HttpErrorResponse} from '@angular/common/http';
import {createAction, props} from '@ngrx/store';
import {StatusFormFilter} from 'src/app/components/stock-manager-components/stock-manager-header-filters/stock-manager-header-filters.component';
import ArticleCategory from 'src/app/models/ArticleCategory';
import {GroupedArticle} from 'src/app/models/GroupedArticle';
import {GroupedOptionArticle} from 'src/app/models/GroupedOptionArticle';
import Hours from 'src/app/models/Hours';
import {OptionGroup} from 'src/app/models/OptionGroup';
import Order from 'src/app/models/Order';
import {OrderPool} from 'src/app/models/OrderPool';
import {OrderStatus} from 'src/app/models/OrderStatus';
import {OrderType} from 'src/app/models/OrderType';
import Screensaver from 'src/app/models/Screensaver';
import {Table} from 'src/app/models/Table';
import {Venue} from 'src/app/models/Venue';

// load optionGroups
export const loadOptionGroups = createAction(
	'[Manager Logic] Load Option Groups',
	props<{venueId: string}>()
);
export const responseErrorOptionGroups = createAction(
	'[Manager Logic] Receive Error Option Groups',
	props<{error: HttpErrorResponse}>()
);
export const responseSuccessOptionGroups = createAction(
	'[Manager Logic] Load Success Option Groups',
	props<{response: OptionGroup[]}>()
);

// load Categories
export const loadCategories = createAction(
	'[Manager Logic] Load Categories',
	props<{venueId: string}>()
);
export const responseErrorCategories = createAction(
	'[Manager Logic] Receive Error Categories',
	props<{error: HttpErrorResponse}>()
);
export const responseSuccessCategories = createAction(
	'[Manager Logic] Load Success Categories ',
	props<{response: ArticleCategory[]}>()
);

// load articles
export const loadArticles = createAction(
	'[Manager Logic] Load Articles',
	props<{
		filters: {
			categories?: string[];
			type?: string;
			status?: string;
			search?: string;
			limit?: number;
		};
		venueId: string;
		currentPage?: number;
	}>()
);

export const loadOptionArticles = createAction(
	'[Manager Logic] Load Option Articles',
	props<{
		filters: {
			category: string;
			type: string;
			status: string;
			search: string;
			limit?: number;
		};
		venueId: string;
		currentPage?: number;
	}>()
);

export const responseSuccessArticles = createAction(
	'[Manager Logic] Load Success Articles',
	props<{
		response: GroupedArticle[];
		pages: number;
		currentPage: number;
	}>()
);

export const responseSuccessOptionArticles = createAction(
	'[Manager Logic] Load Success Options Articles',
	props<{
		response: GroupedOptionArticle[];
		pages: number;
		currentPage: number;
	}>()
);

export const resetOptionArticles = createAction(
	'[Manager Logic] Reset Option Articles'
);

export const resetArticles = createAction('[Manager Logic] Reset Articles');

export const responseErrorArticles = createAction(
	'[Manager Logic] Receive Error Articles',
	props<{error: HttpErrorResponse}>()
);

export const updateArticlePagination = createAction(
	'[Manager Logic] Update Article Pagination',
	props<{pages: number; currentPage: number}>()
);
// load venue
export const loadVenue = createAction(
	'[Manager Logic] Load Venue',
	props<{venueId: string}>()
);
export const responseErrorVenue = createAction(
	'[Manager Logic] Receive Error Venue',
	props<{error: HttpErrorResponse}>()
);
export const responseSuccessVenue = createAction(
	'[Manager Logic] Load Success Venue',
	props<{response: Venue}>()
);

// load ScreenSavers
export const loadScreensavers = createAction(
	'[Manager Logic] Load Screensavers',
	props<{venueId: string}>()
);
export const responseErrorScreensavers = createAction(
	'[Manager Logic] Receive Error ScreenSavers',
	props<{error: HttpErrorResponse}>()
);
export const responseSuccessScreensavers = createAction(
	'[Manager Logic] Load Success ScreenSavers ',
	props<{response: Screensaver[]}>()
);

//load Tables
export const loadTables = createAction(
	'[Manager Logic] Load Tables',
	props<{venueId: string}>()
);
export const responseErrorTables = createAction(
	'[Manager Logic] Receive Error Table',
	props<{error: HttpErrorResponse}>()
);
export const responseSuccessTables = createAction(
	'[Manager Logic] Load Success Table ',
	props<{response: Table[]}>()
);

//load firstOrders before Connection
export const loadOrders = createAction(
	'[Manager Logic] Load Orders',
	props<{
		venue: string;
		status: OrderStatus[];
		orderTypes: OrderType[];
		from: string;
		to: string;
		promoCode: boolean;
	}>()
);
export const responseErrorOrders = createAction(
	'[Manager Logic] Receive Error Orders Loading',
	props<{error: HttpErrorResponse}>()
);
export const responseSuccessOrders = createAction(
	'[Manager Logic] Load Success Orders ',
	props<{response: Order[]}>()
);

//update Order action while Listen Socket
export const updateOrder = createAction(
	'[Manager Logic] update Order',
	props<{order: Order}>()
);

//update Order status in manager Panel
export const updateOrderStatus = createAction(
	'[Manager Logic] update Order Status',
	props<{order: Order; status: OrderStatus | null}>()
);

export const updateOrderStatusSuccess = createAction(
	'[Manager Logic] update Order Status Success',
	props<{order: Order}>()
);
export const updateOrderStatusFailed = createAction(
	'[Manager Logic] update Order Status Failed',
	props<{error: HttpErrorResponse}>()
);

//add filter status
export const filterOrderStatusAdd = createAction(
	'[Manager Logic] add filterStatus',
	props<{status: OrderStatus | null}>()
);

//update Device
export const updateTable = createAction(
	'[Manager Logic] update Table',
	props<{table: Table}>()
);
export const setStockManagerFilters = createAction(
	'[Manager Logic] set Stock Manager Filters',
	props<{
		filters: {
			category: string;
			type: string;
			status: StatusFormFilter;
			search: string;
		};
	}>()
);

//updateArticleAction
export const updateArticlesActive = createAction(
	'[Manager Logic] update Articles Active',
	props<{
		data: {
			articles: string[];
			venue: string;
			action: string;
			isOption: boolean;
		};
	}>()
);
export const updateArticlesActiveSuccess = createAction(
	'[Manager Logic] update Articles Active Success',
	props<{
		respone: any;
	}>()
);
export const updateOptionArticlesInMainAricleActiveSuccess = createAction(
	'[Manager Logic] update Option Articles In Main Aricle Active Success',
	props<{
		data: any;
	}>()
);
export const updateArticlesActiveSuccessTest = createAction(
	'[Manager Logic] update Articles Active Success Test',
	props<{
		data: any;
	}>()
);
export const updateOptionArticlesActiveSuccess = createAction(
	'[Manager Logic] update Option Articles Active Success Test',
	props<{
		data: any;
	}>()
);
export const updateArticlesActiveError = createAction(
	'[Manager Logic] update Articles Active Error',
	props<{
		error: any;
	}>()
);

//update Opening Hours logic
export const updateOpeningHoursAction = createAction(
	'[Manager Logic] update Opening Hours',
	props<{
		data: {hours: Hours[]; venueId: string};
	}>()
);
export const updateOpeningHoursActionSuccess = createAction(
	'[Manager Logic] update Opening Hours Success',
	props<{
		response: Hours[];
	}>()
);
export const updateOpeningHoursActionError = createAction(
	'[Manager Logic] update Opening Hours Error',
	props<{
		error: HttpErrorResponse;
	}>()
);

export const updateArticlesOptionList = createAction(
	'[Manager Logic] update Articles Option List',
	props<{
		data: {
			active: {
				articles: string[];
				venue: string;
				action: string;
				isOption: boolean;
			};
			disabled: {
				articles: string[];
				venue: string;
				action: string;
				isOption: boolean;
			};
			isActive: {
				articles: string[];
				venue: string;
				action: string;
				isOption: boolean;
			};
			inActive: {
				articles: string[];
				venue: string;
				action: string;
				isOption: boolean;
			};
		};
	}>()
);

//syncJet
export const syncJet = createAction(
	'[Manager Logic] Sync Jet',
	props<{
		data: {venueId: string};
	}>()
);
export const syncJetSuccess = createAction(
	'[Manager Logic] Sync Jet Success',
	props<{
		response: any;
	}>()
);
export const syncJetError = createAction(
	'[Manager Logic] Sync Jet Error',
	props<{
		error: HttpErrorResponse;
	}>()
);

//syncUber
export const syncUber = createAction(
	'[Manager Logic] Sync Uber',
	props<{
		data: {venueId: string};
	}>()
);
export const syncUberSuccess = createAction(
	'[Manager Logic] Sync Uber Success',
	props<{
		response: any;
	}>()
);
export const syncUberError = createAction(
	'[Manager Logic] Sync Uber Error',
	props<{
		error: HttpErrorResponse;
	}>()
);

//syncWolt
export const syncWolt = createAction(
	'[Manager Logic] Sync Wolt',
	props<{
		data: {venueId: string};
	}>()
);
export const syncWoltSuccess = createAction(
	'[Manager Logic] Sync Wolt Success',
	props<{
		response: any;
	}>()
);
export const syncWoltError = createAction(
	'[Manager Logic] Sync Wolt Error',
	props<{
		error: HttpErrorResponse;
	}>()
);

//statusUber
export const statusUber = createAction(
	'[Manager Logic] Status Uber',
	props<{
		data: {venueId: string};
	}>()
);

export const statusUberSuccess = createAction(
	'[Manager Logic] Status Uber Success',
	props<{
		isActive: boolean;
	}>()
);
export const statusUberError = createAction(
	'[Manager Logic] Status Uber Error',
	props<{
		error: HttpErrorResponse;
	}>()
);

//statusWolt
export const statusWolt = createAction(
	'[Manager Logic] Status Wolt',
	props<{
		data: {venueId: string};
	}>()
);
export const statusWoltSuccess = createAction(
	'[Manager Logic] Status Wolt Success',
	props<{
		isActive: boolean;
	}>()
);
export const statusWoltError = createAction(
	'[Manager Logic] Status Wolt Error',
	props<{
		error: HttpErrorResponse;
	}>()
);

export const killSuccess = createAction('[Manager Logic] Sync Success Kill');

// load OrderPools
export const loadOrdersPool = createAction(
	'[Manager Logic] Load Orders Pool',
	props<{
		venue: string;
		status: OrderStatus[];
		orderTypes: OrderType[];
		from: string;
		to: string;
		promoCode: boolean;
	}>()
);
export const responseErrorOrdersPools = createAction(
	'[Manager Logic] Receive Error Orders Pools Loading',
	props<{error: HttpErrorResponse}>()
);
export const responseSuccessOrdersPools = createAction(
	'[Manager Logic] Load Success Orders Pools ',
	props<{response: OrderPool[]}>()
);

//update Order action while Listen Socket
export const updateOrderPool = createAction(
	'[Manager Logic] update Order Pool',
	props<{orderPool: OrderPool}>()
);

//update Order status in manager Panel
export const updateOrderPoolStatus = createAction(
	'[Manager Logic] update Order  Pool Status',
	props<{orderPool: OrderPool; status: OrderStatus | null}>()
);

export const updateOrderPoolStatusSuccess = createAction(
	'[Manager Logic] update Order Pool Status Success',
	props<{orderPool: OrderPool}>()
);
export const updateOrderPoolStatusFailed = createAction(
	'[Manager Logic] update Order Pool Status Failed',
	props<{error: HttpErrorResponse}>()
);

//update Order action while Listen Socket
export const updateOrderPoolWithSocket = createAction(
	'[Manager Logic] update Order Pool New Pull',
	props<{orderPool: OrderPool}>()
);
